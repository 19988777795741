import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../Images/logo.png';
import demo from '../Images/mocky.png';
import { Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Nav from '../Component/Nav';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

function Privacy() {
    const classes = useStyles();
  return <div>
      <Box className={classes.Privacy}>
         <img src={logo} alt="logo" style={{ width: "25%", marginTop: '5%' }} /> 
         <h1>Privacy Policy</h1>
         <h5 style={{marginTop:15,color:'#666'}}>he present Privacy Policy is supplied electronically for the registration of the application. A paper format is available on request at connect@careplix.com</h5>
         <h4 style={{marginTop:15,}}>#What is there to know?</h4>
         <h4>First of all, we live and die by our rule: you always remain the sole owner of your data.</h4>
         <h5 style={{marginBottom:10,}}>#What information do we collect about you?</h5>
         <ul>
             <li>
                 <p>When you start using the Careplix Vitals App, we will ask you to create an account to allow secure storage of your data. In this respect we collect limited general personal details such as your name and email address, together with demographic information like your age, gender, country.</p>
             </li>
             <li>
                 <p>By using our Services, you will provide us with your health data and health related data (such as your measurements). Otherwise we cannot do a body scan: Given its sensitive nature, additional technical and organizational measures shall be taken to safeguard your information.</p>
             </li>
             <li>
                 <p>We collect information about the device you use to access the Careplix Vitals App or Careplix Vitals Dashboard to assure quality.</p>
             </li>
         </ul>
         <h5>#Why do we collect this information?</h5>
         <ul>
             <li><p>To assist you in your health journey to timely detect irregular heart rhythm and provide you a comprehensive vital support</p></li>
             <li><p>To provide customer support.</p></li>
             <li><p>To provide customer support.</p></li>
             <li><p>To improve our Services and make sure you can make optimal use of Careplix Vitals.</p></li>
             <li><p>To contact you and keep you up to date about our Services.</p></li>
             <li><p>To send you questionnaires or invite you to provide a review of your experiences with our service(s). We also may get in touch with you regarding feedback, inquiries, and complaints you made regarding our offered Services.</p></li>
             <li><p>In the event a project is funded by your employer, to provide your employer with insights regarding the health of its employees. In case you gave explicit consent, we will provide the occupational physician of your employer’s Health and Safety department with an analysis of your measurements. Otherwise only aggregated data (meaning anonymized data) shall be shared;</p></li>
             <li><p>For scientific and research purposes, we will use and/or share only pseudonymized Personal Data or anonymized data.
Who has access to your data?</p></li>
             <li><p>Your Personal Data will never be sold or made available to other parties. Remember our mantra? You always remain the sole owner of your data.</p></li>
             <li><p>Only a select number of people of the trustworthy Careplix Vitals team has access to your information.</p></li>
             <li><p>We do make use of external suppliers and service providers, such as web hosting services. These parties are legally obliged to always ensure your privacy. They can only process data in accordance with our purposes.</p></li>
             <li><p>We will only share your pseudonymized Personal Data (for scientific and research purposes) or anonymized data.
#What do you control?</p></li>
             <li><p>It is your data, so you are in control:
Want to modify your Personal Data? You can do so yourself in the Careplix Vitals App.
Want to know what information we collect from you? Almost all the data is visible in your personal dashboard. Or you can request a copy of all the information we collected about you by sending an email to connect@careplix.com</p></li>
             
         </ul>
         <h5>#How we protect your data?</h5>
         <p>A multitude of safety measures have been put in place on both a technical and organizational level.
We implemented internal procedures to ensure the confidentiality of our IT-infrastructure and to make sure it is managed responsibly.</p>
<p>Want to know more? Scroll down to read our Privacy Policy in its entirety.</p>
<p>We CareNow Healthcare Pvt Ltd., are registered at 71D/1 Swinhoe Lane 2nd Floor Kolkata West Bengal - 700042 hereinafter referred to as Careplix Vitals. At Careplix Vitals, we value your trust & respect your privacy. This Privacy Policy provides you with details about the way your data is collected, stored & used by us. You are advised to read this Privacy Policy carefully. By downloading and using the Careplix Vitals application/ website/WAP site you expressly give us consent to use & disclose your personal information in accordance with this Privacy Policy. If you do not agree to the terms of the policy, please do not use or access Careplix Vitals.</p>
<p>Note: Our privacy policy may change at any time without prior notification. To make sure that you are aware of any changes, kindly review the policy periodically. This Privacy Policy shall apply uniformly to Careplix Vitals applications, desktop website & mobile WAP site.</p>
<h6>General</h6>
<p>
We will not sell, share, or rent your personal information to any 3rd party or use your email address/mobile number for unsolicited emails and/or SMS. Any emails and/or SMS sent by Careplix Vitals will only be in connection with the provision of agreed services & products and this Privacy Policy.
Periodically, we may reveal general statistical information about Careplix Vitals & its users, such as number of visitors, number and type of goods and services purchased, etc. We reserve the right to communicate your personal information to any third party that makes a legally compliant request for its disclosure.</p>
<h6>Personal Information</h6>
<p>Personal Information means and includes all information that can be linked to a specific individual or to identify any individual, such as name, address, , email ID and any details that may have been voluntarily provide by the user in connection with availing any of the services on Careplix Vitals.
When you browse through Careplix Vitals, we may collect information regarding the mobile/ tab device details, domain and host from which you access the internet, the Internet Protocol [IP] address of the computer or Internet service provider [ISP] you are using, and anonymous site statistical data.</p>
<h6>Use of Personal Information</h6>
<p>We use personal information to provide you with services & products you explicitly requested for, to resolve disputes, troubleshoot concerns, help promote safe services, collect money, measure consumer interest in our services, inform you about offers, products, services, updates, customize your experience, detect & protect us against error, fraud and other criminal activity, enforce our terms and conditions, etc.</p>
<p>We also use your contact information to send you offers based on your previous orders and interests.
We may occasionally ask you to complete optional online surveys. These surveys may ask you for contact information and demographic information (like zip code, age, gender, etc.). We use this data to customize your experience at Careplix Vitals, providing you with content that we think you might be interested in and to display content according to your preferences.</p>
<h6>Cookies</h6>
<p>A "cookie" is a small piece of information stored by a web server on a web browser so it can be later read back from that browser. Careplix Vitals uses cookie and tracking technology depending on the features offered. No personal information will be collected via cookies and other tracking technology; however, if you previously provided personally identifiable information, cookies may be tied to such information. Aggregate cookie and tracking information may be shared with third parties.</p>
<h6>Links to Other Sites</h6>
<p>Our site links to other websites that may collect personally identifiable information about you. Careplix Vitals is not responsible for the privacy practices or the content of those linked websites.</p>
<h6>Security</h6>
<p>Careplix Vitals has stringent security measures in place to protect the loss, misuse, and alteration of the information under our control. Whenever you change or access your account information, we offer the use of a secure server. Once your information is in our possession we adhere to strict security guidelines, protecting it against unauthorized access. Consent</p>
<p>By using Careplix Vitals and/or by providing your information, you consent to the collection and use of the information you disclose on Careplix Vitals in accordance with this Privacy Policy, including but not limited to your consent for sharing your information as per this privacy policy.</p>
      </Box>
  </div>;
}

export default Privacy;
const useStyles = makeStyles(() => ({
    Privacy:{
        display:'flex',
        flexDirection:'column',
        width:'90%',
        textAlign:'left',
        padding:'0 5% 30px',
        '& p':{
            fontSize:15,
            lineHeight:1.5,
            textAlign:'justify'
        },
        '& ul':{
            padding:'0 0 0 20px',
            
        },
        '& ul li':{
            fontSize:15,
            lineHeight:1.5,
        },
        '& a':{
            textDecoration:'none'
        },
        '& .MuiListItem-button:hover':{
            background:'rgba(47, 153, 241, 0.1)',
        },
        '& h5':{
            fontSize:16,
            fontStyle:'italic',
            marginBottom:15,
        },
        '& h4':{
            fontSize:18,
            marginBottom:15,
            marginTop:0,
        },
        '& h6':{
            fontSize:16,
            marginBottom:5,
            marginTop:15,
        },
        '& h1':{
            fontSize:28,
            marginTop:30,
        }

    },
    
   }));