import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../Images/logo.png";
import heartbeatgif from "../Images/heartbeat.gif";
import loaderGif from "../Images/loader.gif";
import faceOutline from "../Images/icon.png";
import GQFRr from "../Images/success.gif";
import analysis from "../Images/analysis.gif";
import failureGif from "../Images/failure.gif";
import demo from "../Images/mocky.png";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { fingerScan } from "../Algo/videoProcess2";
import { API_KEY, apiAxios3 } from "../Util/ApiAxios";
import "../Algo/fft";
import NoSleep from 'nosleep.js';

function FingerScan(props) {
  const classes = useStyles();

  const [showLoader, SetShowLoader] = React.useState(true);
  const [data, setData] = React.useState("");
  const [fps, setFPS] = React.useState(true);

  const [loader, setLoader] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [failure, setFailure] = React.useState(false);

  const [raw, setRaw] = React.useState("");
  const [time, setTime] = React.useState("");
  const [FPSCount, setFPSCount] = React.useState(10);
  const [videoHeight, setVideoHeight] = React.useState(0);
  const [videoWidth, setVideoWidth] = React.useState(0);

  const noSleep = new NoSleep();

  useEffect(() => {
    if (!localStorage.getItem("scan_token")) {
      props.history.push("/select-option");
    } else {

      if(localStorage.getItem("scan_result")){
        localStorage.removeItem("scan_result");
      }

      fingerScan(setRaw, setTime, setFPSCount, setVideoHeight, setVideoWidth);
      SetShowLoader(false);
    }

    document.addEventListener('load', function enableNoSleep() {
      document.removeEventListener('load', enableNoSleep, false);
      noSleep.enable();
    }, false);


  }, []);

  useEffect(() => {
    if (raw.length > 0 && time.length > 0 && FPSCount) {
      setLoader(true);

      let device = "";

      if(FPSCount == 0){
        localStorage.removeItem("scan_token");
        props.history.push("/select-option");
      }


      if (navigator.platform.match(/iPhone|iPod|iPad/)) {
        device = "RPPG_CAREPLIX_FINGER_IOS";
      } else {
        device = "RPPG_CAREPLIX_FINGER_ANDROID";
      }

      let data = {
        scan_token: localStorage.getItem("scan_token"),
        api_key: API_KEY,
        employee_id: localStorage.getItem("emp_id")
          ? localStorage.getItem("emp_id")
          : "",
        heart_rate: 0,
        oxy_sat_prcnt: 1,
        posture: localStorage.getItem("posture"),
        resp_rate: 16,
        long: null,
        lat: null,
        ref_id: "",
        updateables: ["heart_rate", "oxy_sat_prcnt", "resp_rate"],
        metadata: {
          physiological_scores: {
            height: localStorage.getItem("height"),
            weight: localStorage.getItem("weight"),
          },
          ppg_time: time,
          raw_intensity: raw,
          fps: FPSCount,
          device: device,
        },
        platform: "ios",
        app_version: "5.0.0",
      };

      apiAxios3
        .post("/vitals/add-scan", data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.statusCode == 200) {
            localStorage.removeItem("scan_token");
            localStorage.setItem("scan_result", JSON.stringify(response.data));
            setSuccess(true);

            noSleep.disable();

            setTimeout(function () {
              props.history.push("/result");
            }, 2500);
          } else {
            toast.error(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setFailure(true);
            setTimeout(function () {
              props.history.push("/select-option");
            }, 4000);
          }
        })
        .catch((error) => {
          toast.error(error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setFailure(true);
            setTimeout(function () {
              props.history.push("/select-option");
            }, 4000);
        });
    }

    if(FPSCount == 0){

      toast.warn(
        "Measurement failed due to inappropriate data. Please try again.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );

      var delayInMilliseconds = 7000; //5 second
      setTimeout(function () {
        window.location.href="/dashboard";
      }, delayInMilliseconds);

    }


  }, [raw]);

  const cancelBtnFunc = () => {
    
    window.location.href="/dashboard";
  }

  useEffect(() => {
    if (fps == false) {
      toast.warn(
        "We are unable to get good quality data from your front camera. You can try the finger scan process with your back camera.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );

      var delayInMilliseconds = 5000; //5 second
      setTimeout(function () {
        props.history.push("/select-option");
      }, delayInMilliseconds);
    }
  }, [fps]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div id="container" style={{ display: "none" }}></div>

      {loader ? (
        <Box className={classes.Scan} style={{ background: "#fff" }}>
          <Box
            style={{
              width: "100%",
              marginTop: "25%",
              height: "50%",
              padding: "50% 0 0 0",
            }}
          >
            {!success ? (
              <>
                {!failure ? (
                  <>
                    <img
                      src={analysis}
                      alt="success icon"
                      style={{ width: "50%", paddingTop: "2.5%" }}
                    />
                    <br />
                    <span style={{ color: "#22538f" }}>Analysing data...</span>
                  </>
                ) : (
                  <>
                    <img
                      src={failureGif}
                      alt="failure icon"
                      style={{ width: "50%", paddingTop: "2.5%" }}
                    />
                    <br />
                    <span style={{ color: "#22538f" }}>Oops..We couldn't get enough data<br/> to process...Please try again.</span>
                  </>
                )}
              </>
            ) : (
              <>
                <img
                  src={GQFRr}
                  alt="success icon"
                  style={{ width: "50%", paddingTop: "2.5%" }}
                />
                <br />
                <span style={{ color: "#22538f" }}>Scan Successful...</span>
              </>
            )}
          </Box>
        </Box>
      ) : (
        <Box className={classes.Scan}>
          <Box className={classes.logo}>
            <img
              src={logo}
              alt="logo"
              style={{ height: "65px", width:'65px', paddingTop: "2.5%" }}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "90%",
              position: "relative",
            }}
          >
            <Box className={classes.ScanCamera}>
              <img
                src={loaderGif}
                alt="loader"
                style={{ width: "30%", height: "auto", paddingTop: "25%" }}
                id="instruction"
              />
              {/* <canvas hidden id="facecanvas" style={{width:'100%', height:'100%'}}></canvas> */}
              <video
                playsInline
                autoPlay
                muted
                hidden
                id="video"
                style={{ width: "100%", height: "100%" }}
              ></video>
              {/* <img id="face" /> */}
              <canvas
                id="canvasOutput"
                style={{ width: "100%", height: "100%" }}
              ></canvas>
            </Box>
            <Box className={classes.ScanCameraOverlay}>
              <img
                src={heartbeatgif}
                alt="heartbeat"
                style={{ width: "60%", height: "auto", padding: "50% 20% 0 20%" }}
                id="heartbeat"
              />
            </Box>
            {/* <Box className={classes.Instru}>
              <p>
                Place your finger on the rear primary camera till the screen is
                red and hold steady.
              </p>
            </Box> */}
            {showLoader ? (
              <Box className={classes.ScanInfo}>
                <h3>Opening Camera</h3>
                <h4>Scan Is About To Start</h4>

                <Link
                  to="/select-option"
                  onClick={() => props.history.push("/select-option")}
                >
                  <Button className={classes.CancelBtn}>Cancel Scan</Button>
                </Link>
              </Box>
            ) : (
              <Box className={classes.ScanInfo}>
                <h3>
                  <span id="time">Accessing Camera</span>
                </h3>
                <h4 id="sub_text">Please allow camera access from your phone and site settings in case it doesn't open automatically.</h4>
                <h5 id="total_frames"></h5>
                {/* <a id="download-video" download="test.webm">Download Video</a> */}
                <h5 id="fps"></h5>
                <h5 id="cameraFPS2"></h5>
                <h5 id="peaks"></h5>

                <Button
                  onClick={() => cancelBtnFunc()}
                  className={classes.CancelBtn}
                >
                  Cancel Scan
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
}

export default FingerScan;
const useStyles = makeStyles(() => ({
  Scan: {
    display: "flex",
    height: "100vh",
    left: 0,
    right: 0,
    background: "linear-gradient(180deg, #34476f14, white)",
    flexDirection: "column",
    "& a": {
      textDecoration: "none",
    },
  },
  Instru: {
    color: "#000",
    padding: "2% 5%",
    fontSize: "12px",
    backgroundColor: "#fff",
    height: "5%",
    "& p": {
      margin: 0,
      fontFamily: "Poppins",
    },
  },
  ScanInfo: {
    width: "100%",
    position: 'fixed',
    bottom: 0,
    background: "linear-gradient(315deg, #da8f8936 0%, #C7222A40 92.7%)",
    height: "45%",
    "& p": {
      fontFamily: "Poppins",
      color: "rgba(255,255,255,0.7)",
      fontWeight: "400",
      fontSize: 12,
      padding: "0 20px",
      marginTop: 0,
      marginBottom: 30,
      lineHeight: 1.6,
    },
    "& h4": {
      fontFamily: "Poppins",
      color: "rgba(255,255,255,0.8)",
      fontWeight: "400",
      fontSize: 16,
      marginTop: 20,
      marginBottom: 10,
    },
    "& h3": {
      fontSize: 16,
      fontFamily: "Poppins",
      color: "#fff",
      fontWeight: "400",
      marginTop: 40,
      marginBottom: 0,
      "& span": {
        fontSize: 24,
        fontWeight: "600",
        color: "#fff",
        marginRight: 5,
      },
    },
    "& h5": {
      color: "#fff",
      fontSize: 16,
      margin: "1% 0",
    },
  },
  ScanCamera: {
    width: "100%",
    position: "relative",
    height: "100%",
    textAlign: "center",
  },
  ScanCameraOverlay: {
    width: "100%",
    position: "absolute",
    height: "50%",
    textAlign: "center",
    top: 0,
    left: 0,
  },
  logo: {
    height: "10%",
    justifyContent: "flex-start",
    textAlign: "left",
    padding: "0 30px 0",
    background: "#fff",
  },
  CancelBtn: {
    fontFamily: "Poppins",
    fontSize: 16,
    color: "#C7222A",
    textTransform: "capitalize",
    borderRadius: 50,
    padding: "5px 20px",
  },
}));
