import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../Images/logo.png';
import camera from '../Images/camera-img.png';
import demo from '../Images/mocky.png';
import { Box} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core//Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import { Link } from 'react-router-dom';
import faceIcon from '../Images/face-scan.png';
import fingerIcon from '../Images/finger-scan.png';
import DatePicker from 'react-date-picker';
import "react-datepicker/dist/react-datepicker.css"
import { apiAxios2 } from '../Util/ApiAxios'
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';
import loader from "../Images/loader.gif";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import { MotionAnimate } from 'react-motion-animate';
import { MdHelp } from "react-icons/md";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Email from '../Images/mail.svg';
import Call from '../Images/accept-call.svg';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function BasicDetails(props) {

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  function Mailto({ email, subject, body, ...props }) {
    return (
      <a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
        {props.children}
      </a>
    );
  }
  const Callto = ({ phone, children }) => {
    return <a href={`tel:${phone}`}>{children}</a>;
  };
    const [showLoader, setShowLoader] = React.useState(false);

    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [dob, setDOB] = React.useState(new Date());
    // const [dob, setDOB] = React.useState(null);
    const [gender, setGender] = React.useState("Male");
    const [height, setHeight] = React.useState("");
    const [weight, setWeight] = React.useState("");

    const [selected, setSelected] = React.useState("");
    const [selectedGender, setSelectedGender] = React.useState("male");

    const [firstNameError, setFirstNameError] = React.useState(false);
    const [lastNameError, setLastNameError] = React.useState(false);
    const [dobError, setDobError] = React.useState(false);
    const [heightError, setHeightError] = React.useState(false);
    const [weightError, setWeightError] = React.useState(false);
    const [isChecked, setIsChecked] = React.useState(false);
    const [isCheckedError, setIsCheckedError] = React.useState(false);
    const [userId, setUserId] = React.useState("")
    const [dobFormat, setDobFormat] = React.useState("")

    const [isCheckedABHI, setIsCheckedABHI] = React.useState(false);
    const [isCheckedABHIError, setIsCheckedABHIError] = React.useState(false);

    const [pageCounter, setPageCounter] = React.useState(1);


    // getting terms checkbox value
 const getCheckValue = (e)=>{
    
    const {checked}= e.target;
    setIsChecked(checked)
    console.log(isChecked)
 }

 const getCheckABHIValue = (e)=>{
    
    const {checked}= e.target;
    setIsCheckedABHI(checked)
 }



  const onUserSubmit = ()=>{
    
    
    
    

    let isValid = true;
   

    if(height >=30 && height <= 250){
        setHeightError(false);
    }else{
        setHeightError(true);
        isValid = false;   
    }
    if(weight >= 5 && weight <= 220){
        setWeightError(false);
    }else{
        setWeightError(true);
        isValid = false;   
    }
    if(isChecked==true){
        setIsCheckedError(false);
    }else{
        setIsCheckedError(true);
        isValid = false; 
    }
    if(isCheckedABHI==true){
        setIsCheckedABHIError(false);
    }else{
        setIsCheckedABHIError(true);
        isValid = false; 
    }

    if(isValid) {

        setShowLoader(true);
        
        let userDetails = JSON.parse(localStorage.getItem("user_details")) 

    // console.log(userDetails.id_token)

    // changing date format for the payload

        let DOB =  moment(dob).format("YYYY-MM-DD");
    
        
        let data = {
      
            first_name: firstName,  
            last_name: lastName,
            dob: DOB,
            is_terms_accepted: isChecked,
            user_id: userDetails.user_id,
            gender: selectedGender,
            height: height,
            weight: weight
            
        };

    apiAxios2
          .post("/users/profile/update", data, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": userDetails.id_token
            },
          })
          .then((response) => {
            console.log(response.data);
            if (response.data.statusCode == 200) {
                toast.success(response.data.message, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
      
                // localStorage.setItem("user_details", JSON.stringify(response.data.user));
                // localStorage.removeItem("phone");
  
                setShowLoader(false);
                props.history.push("/survey");
                
      
              } else {
                toast.error(response.data.message, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setShowLoader(false);
              }
            })
            .catch((error) => {
                toast.error(error.message, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setShowLoader(false);
              });
        
        

    
    }


  }








  const changePage = (flag) => {

    // 

    let isValid = true;

    if(flag == 1){

        if(pageCounter == 1){
            if((firstName.trim()).length > 0){
                setFirstNameError(false);
            }else{
                setFirstNameError(true);
                isValid = false;   
            }
            if((lastName.trim()).length > 0){
                setLastNameError(false);
            }else{
                setLastNameError(true);
                isValid = false;   
            }
        }
        else if(pageCounter == 2) {


            var myBirthday
            var currentDate 
            var myAge
            if(dob === null){
                // var myBirthday = 0
                // var currentDate =0
                // var myAge = 0
                var myBirthday = undefined;
                var currentDate = undefined;
                var myAge = undefined;
            }else {
                 var myBirthday = new Date(dob)
                 var currentDate = new Date().toJSON().slice(0,10)+' 01:00:00';
                 var myAge = ((Date.now(currentDate) - myBirthday) / (31557600000));
            }
        

            
            // //set date based on birthday at 01:00:00 hours GMT+0100 (CET)            
            
            // var myBirthday = new Date(dob);
            
            // console.log(myBirthday)

            // // set current day on 01:00:00 hours GMT+0100 (CET)
            // var currentDate = new Date().toJSON().slice(0,10)+' 01:00:00';

            // // calculate age comparing current date and borthday
            // var myAge = ((Date.now(currentDate) - myBirthday) / (31557600000));

            console.log("Age : ", myAge);

            if(myAge >= 18 && myAge <=110){
                setDobError(false);
            }else{
                setDobError(true);
                isValid = false;   
            }

        }
        // else if(pageCounter == 3){
        //     if(height >=30 && height <= 250){
        //         setHeightError(false);
        //     }else{
        //         setHeightError(true);
        //         isValid = false;   
        //     }
        //     if(weight >= 5 && weight <= 220){
        //         setWeightError(false);
        //     }else{
        //         setWeightError(true);
        //         isValid = false;   
        //     }
        //     if(isChecked==true){
        //         setIsCheckedError(false);
        //     }else{
        //         setIsCheckedError(true);
        //         isValid = false; 
        //     }

        // }

        // 

        if(isValid) {
            setPageCounter(pageCounter+1);
        }


        
    }
    else{
        setPageCounter(pageCounter-1);
    }

  };  

  const handleChangeSelected = (event) => {

    //console.log("Selected = ", selected);
    setSelectedGender(event.target.value);
   
  };

  
    
  return <>
    
    <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> 



      <Box className={classes.Scan}>
      <Box className={classes.logo}>
          <img src={logo} alt="logo" style={{height:'65px',width:'65px',}} />
          <MdHelp onClick={handleClickOpen} size='26' color='rgb(199, 34, 42)'/>
          </Box>
        <Box style={{display:'flex',justifyContent:'space-between',flexDirection:'column', height:'90%'}}> 
            <Box className={classes.basic}>
                <Box className={classes.loginform}>
                    <p style={{fontSize:18,color:'#C7222A',textAlign:'left',fontFamily:'Poppins',fontWeight:'500'}}>Basic Details &nbsp;&nbsp;&nbsp;<span style={{color: '#e3e3e3', textAlign:'right', fontSize:'12px',padding:'1.5%',borderRadius:'50%',backgroundColor: '#C7222A'}}>{pageCounter}/3</span></p>
                    <MotionAnimate
                        animation='fadeInUp'
                        reset={true}
                        delay={0.5}
                        speed={1}>
                        <form style={{textAlign:'left'}}>
                            {(pageCounter == 1) ?
                            <>
                            <FormLabel id="demo-radio-buttons-group-label" className={classes.customlabel}>First Name</FormLabel>
                            <TextField className={classes.input} value={firstName} placeholder="Enter First Name" onChange={(e) => setFirstName(e.target.value)} type="text"/>
                            {firstNameError ? (
                                <p
                                style={{
                                    color: "#dc3545",
                                    fontSize: ".875em",
                                    marginTop: "0.25rem",
                                    textAlign: 'right'
                                }}
                                >
                                First name is mandatory.
                                </p>
                            ) : (
                                <></>
                            )}

                            <FormLabel id="demo-radio-buttons-group-label" className={classes.customlabel}>Last Name</FormLabel>
                            <TextField className={classes.input} value={lastName} placeholder="Enter Last Name" onChange={(e) => setLastName(e.target.value)} type="text"/>
                            {lastNameError ? (
                                <p
                                style={{
                                    color: "#dc3545",
                                    fontSize: ".875em",
                                    marginTop: "0.25rem",
                                    textAlign: 'right'
                                }}
                                >
                                Last name is mandatory.
                                </p>
                            ) : (
                                <></>
                            )}

                            </>
                            :
                            <></>
                            }   
                            {(pageCounter == 2) ?
                            <> 
                            
                            <FormControl style={{width:'100%', marginBottom: '5%'}}>
                                <FormLabel id="demo-radio-buttons-group-label" className={classes.customlabel}>Gender</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue={selectedGender}
                                    value={selectedGender}
                                    name="radio-buttons-group"
                                    onChange={handleChangeSelected}
                                >
                                    <FormControlLabel className={classes.customRadio} value="female" control={<Radio />} label="Female" />
                                    <FormControlLabel className={classes.customRadio} value="male" control={<Radio />} label="Male" />
                                    <FormControlLabel className={classes.customRadio} value="other" control={<Radio />} label="Other" />
                                </RadioGroup>
                            </FormControl>

                            <FormLabel id="demo-radio-buttons-group-label" className={classes.customlabel}>Date Of Birth</FormLabel>
                            <DatePicker className={classes.datepicker} value={dob} placeholder="Enter Date of Birth" selected={dob} onChange={(date) => setDOB(date)} style={{border: '1px solid #C7222A', padding: '0 15px'}} format="dd-MM-y"  maxDate={new Date()} autoOk={true} clearIcon={null} />
                            {dobError ? (
                                <p
                                style={{
                                    color: "#dc3545",
                                    fontSize: ".875em",
                                    marginTop: "0.25rem",
                                    textAlign: 'right'
                                }}
                                >
                                Enter a valid age between 18 to 110 years.
                                </p>
                            ) : (
                                <></>
                            )}    

                            </>
                            :
                            <></>
                            }


                            {(pageCounter == 3) ?
                            <> 
                            <FormLabel id="demo-radio-buttons-group-label" className={classes.customlabel}>Height</FormLabel>
                            <TextField className={classes.input} value={height} placeholder="Enter Height in cms" onChange={(e) => setHeight(e.target.value)} type="number"/>
                            {heightError ? (
                                <p
                                style={{
                                    color: "#dc3545",
                                    fontSize: ".875em",
                                    marginTop: "0.25rem",
                                    textAlign: 'right'
                                }}
                                >
                                Height is mandatory, please enter a valid height.
                                </p>
                            ) : (
                                <></>
                            )}

                            <FormLabel id="demo-radio-buttons-group-label" className={classes.customlabel}>Weight</FormLabel>
                            <TextField className={classes.input} value={weight} placeholder="Enter Weight in Kgs" onChange={(e) => setWeight(e.target.value)} type="number"/>
                            
                            

                           


                            {weightError ? (
                                <p
                                style={{
                                    color: "#dc3545",
                                    fontSize: ".875em",
                                    marginTop: "0.25rem",
                                    textAlign: 'right'
                                }}
                                >
                                Weight is mandatory, please enter a valid weight.
                                </p>
                            ) : (
                                <></>
                            )}

                            </>
                            :
                            <></>
                            }
                             
                            
                             
                          
                            {(pageCounter == 3) ?
                            <> 
                            
                            <Box style={{width:'100%'}}>
                            <Checkbox style={{width:'10%'}} color= "default" value={true} checked={isChecked} onChange={(e)=>getCheckValue(e)} />
                            <span style={{fontSize: '12px', width: '80%'}}>I accept the <Link target="_blank" to="/terms" style={{color: '#dc3545'}}>terms of use</Link> and <Link target="_blank" to="/privacy" style={{color: '#dc3545'}}>privacy policy</Link>.</span>

                            {isCheckedError ? (
                                <p
                                style={{
                                    color: "#dc3545",
                                    fontSize: ".875em",
                                    marginTop: "0.25rem",
                                    textAlign: 'right'
                                }}
                                >
                                Please accept the terms and conditions.
                                </p>
                            ) : (
                                <></>
                            )}
                            </Box>

                            <Box style={{width:'100%'}}>    
                            <Checkbox style={{width:'10%', float: 'left'}} color= "default" value={true} checked={isCheckedABHI} onChange={(e)=>getCheckABHIValue(e)} />
                            <div style={{fontSize: '12px', width:'80%', float: 'left'}}>I authorize ABHICL and associate partners to contact me through me email/call/SMS. This will override registry on the DNCR.</div>

                            {isCheckedABHIError ? (
                                <p
                                style={{
                                    color: "#dc3545",
                                    fontSize: ".875em",
                                    marginTop: "0.25rem",
                                    textAlign: 'right'
                                }}
                                >
                                Consent is required.
                                </p>
                            ) : (
                                <></>
                            )} 
                            </Box>

                                <Box style={{justifyContent:'flex-end',marginTop:'25%', textAlign: 'center'}}>  
                                        {showLoader?
                                        <>
                                        <img src={loader} alt="loader" style={{width:'50px',height:'50px'}} />
                                        </>
                                        :        
                                        <>       
                                        <Button size="large" className={classes.loginbtn2} onClick={() => changePage(2)}>
                                        Back
                                        </Button>         
                                        <Button size="large" className={classes.loginbtn} onClick={onUserSubmit}>
                                        Save 
                                        </Button>
                                        </>
                                        }
                                </Box>
                            </>
                            :
                            <>
                                <Box style={{display:'flex',justifyContent:'flex-end',marginTop:'10%'}}>  
                                    { (pageCounter > 1 && pageCounter < 3) ?                      
                                    <>
                                    <Button size="large" className={classes.loginbtn2} onClick={() => changePage(2)}>
                                        Back
                                    </Button>
                                    <Button size="large" className={classes.loginbtn} onClick={() => changePage(1)}>
                                        Next
                                    </Button>
                                    </>
                                    :
                                    <>
                                    <Button size="large" className={classes.loginbtn} onClick={() => changePage(1)}>
                                        Next
                                    </Button>
                                    </>
                                    }
                                </Box>
                            </>
                            }
                            
                        </form>
                        <Dialog className={classes.Modal}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Box style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'85%',margin:'10px auto 0px'}}>
          <h2 style={{fontFamily:'Poppins',fontSize:20}}>Need Help?</h2>
           <Button onClick={handleClose} color="primary" style={{width:30,height:30,minWidth:30,borderRadius:50,background:'#C7222A',color:'#fff',display:'flex',alignItems:'center',justifyContent:'center',}}>
            X
          </Button></Box>
        <DialogContent>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "88%",
            background:'#fff',
            overflowY:'auto',
          }}
        >
            <Box className={classes.ReportTable}>
            <List component="nav" aria-label="main mailbox folders">
            <ListItem button className={classes.SupportNav}>
            <Mailto email="abw.wellnesssaver@adityabirlacapital.com" subject="Hello" body="Hello world!" style={{width:'100%',display:'flex'}}>
                <Box style={{display:'flex',alignItems:'center',justifyContent:'space-between',background:'rgb(240 227 227)',borderRadius:15,padding:15,}}>
                <Box><p>Email:</p><p style={{fontSize: '10px', wordBreak: 'break-word'}}>abw.wellnesssaver@adityabirlacapital.com</p></Box>
                <Box><img src={Email} alt="email"/></Box>
                </Box>
                </Mailto>
            </ListItem>
            <ListItem>
            <Callto phone="+918369644246">
            <Box style={{display:'flex',alignItems:'center',justifyContent:'space-between',background:'rgb(245 200 200)',borderRadius:15,padding:15,}}>
                <Box><p>Phone:</p><p>+91-8369644246</p></Box>
                <Box><img src={Call} alt="Call"/></Box>
                </Box>
            </Callto>
            </ListItem>
            </List>
            </Box>
        </Box>
        </DialogContent>
       
      </Dialog>
                    </MotionAnimate>    
                </Box>
            </Box>   
        </Box>
      </Box>
  </>;
}

export default BasicDetails;
const useStyles = makeStyles(() => ({
    Scan:{
        display:'flex',
        height:'100vh',
        left:'0',
        right: '0',
        background: 'linear-gradient(180deg, #c1bfbf14, white)',
        flexDirection:'column',
        '& a':{
            textDecoration:'none'
        }
    },
    Modal:{
        '& .MuiDialog-paper':{
          margin:'0px !important',
        },
        '& .MuiPaper-rounded':{
          width:'90%',
          borderRadius:15
        },
        '& .MuiListItem-gutters':{
          paddingLeft:0,
          paddingRight:0
        }
      },
      ReportTable:{
        width:'100%',
        paddingBottom:20,
        '& p':{
            fontFamily:'Poppins',
            fontSize:16,
            margin:0,
            fontWeight:'500'
        },
        '& a':{
          width:'100%',
        }
    },
      Helpbtn:{
        color:'#C7222A',
        textTransform:'capitalize',
        fontFamily:'Poppins',
        fontSize:16
      },
    customlabel:{
        textAlign: 'left !important',
        color: '#000000',
        paddingBottom: '5px',
        fontSize: '14px'
    },
    customRadio:{
        border: '1px solid #000000',
        borderRadius: '10px',
        height: '50px',
        margin: '0',
        background: '#fff',
        marginBottom: '8px',
        '& .MuiRadio-colorSecondary.Mui-checked':{
            color: '#C7222A'
        }
    },
    optionIcon:{
       width: '100%',
       textAlign: 'center',
       height: '50%',
       '& h4':{
        fontFamily:'Poppins',
        color:'#C7222A',
        fontWeight:'400',
        fontSize:16,
        marginTop:20,
        marginBottom:10,
        }, 
    },
    ScanInfo:{
        width:'100%',
        background:'linear-gradient(180deg, #C7222A -92.7%, #C7222A 92.7%)',
        height:'38vh',
        '& p':{
            fontFamily:'Poppins',
            color:'rgba(255,255,255,0.7)',
            fontWeight:'400',
            fontSize:12,
            padding:'0 20px',
            marginTop:0,
            marginBottom:30,
            lineHeight:1.6,
        },
        '& h4':{
            fontFamily:'Poppins',
            color:'rgba(255,255,255,0.8)',
            fontWeight:'400',
            fontSize:16,
            marginTop:20,
            marginBottom:10,
        },
        '& h3':{
            fontSize:16,
            fontFamily:'Poppins',
            color:'#fff',
            fontWeight:'400',
            marginTop:40,
            marginBottom:0,
            '& span':{
                fontSize:24,
                fontWeight:'600',
                color:'#C7222A',
                marginRight:5,
            }
        }
    },
    ScanCamera:{
        width:'100%',
        height:'49.7vh'
    },
    logo:{
        height: '10%',
        justifyContent:'space-between',
        textAlign:"left",
        padding:'10px 30px 0',
        background: '#fff',
        alignItems:'center',
        display:'flex'
    },
    CancelBtn:{
        fontFamily:'Poppins',
        fontSize:16,
        color:'#C7222A',
        textTransform:'capitalize',
        borderRadius:50,
        padding:'5px 20px'
    },
    input:{ 
        border:'none',
        borderRadius:'10px',
        height:'50px',
        width:'100%',
        backgroundColor: '#fff',
        marginBottom:'20px',
        marginTop:'3%'
    },
    datepicker:{ 
        border:'none',
        borderRadius:'10px',
        height:'50px',
        width:'100%',
        marginBottom:'20px',
        padding: '0 0 0 5%',
        border: '1px solid #000000'
    },
    basic:{
        width:'100%',
        height: '50%',
        paddingTop: '5%',
        paddingBottom: '10%',
        '& .MuiInput-underline:before':{
            display:'none'
        },
        '& .MuiInput-underline:after':{
            display:'none'
        },
        '& .MuiInput-formControl':{ 
            height:'50px',
            
        },
        '& .MuiInputBase-input':{
            height:'50px',
            borderRadius:'10px',
            background:'#fff',
            padding:'0 15px',
            border:'1px solid #000000'
        },
        '& .MuiInput-input:focus':{
            border:'1px #7087A7 solid',
            boxShadow:'2px 2px 10px 1px rgba(0,0,0,0.3)'
        }
    },
    loginbtn:{
        background:'linear-gradient(90deg, #C7222A 0%, #C7222A 100%)',
        padding:'0 20px',
        width:'142px',
        height:'50px',
        borderRadius:'50px',
        color:'#fff',
        marginTop:'0',
        textTransform:'capitalize',
        
    },
    loginbtn2:{
        
        padding:'0 20px',
        width:'142px',
        height:'50px',
        borderRadius:'50px',
        color:'#C7222A',
        marginTop:'0',
        textTransform:'capitalize',
        
    },
    loginform: {
        padding: '0 30px'
    }

   }));