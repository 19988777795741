import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../Images/logo.png";
import face_poster from "../Images/video_poster_face.png";
import finger_poster from "../Images/video_poster_finger.png";
import camera from "../Images/camera-img.png";
import demo from "../Images/mocky.png";
import { Box } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Nav from "../Component/Nav";
import ReactPlayer from "react-player/youtube";
import VideoPlayer from 'react-simple-video-player';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import faceVideo from '../Images/face_video.mp4';
import fingerVideo from '../Images/finger_video.mp4';

function TutorialList() {
  const classes = useStyles();
  const playerRef = useRef(null);
  const [playing, setPlaying] = useState(true);
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);
  const [open, setOpen] = React.useState(false);
  const [videoURL, setVideoURL] = React.useState("");


  const handleClickOpen = (flag) => {
    if(flag == 1){
      setVideoURL(faceVideo);
    }
    else{
      setVideoURL(fingerVideo);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  return (
    <div>
      <Box className={classes.Scan}>
        <Box className={classes.logo}>
          <img src={logo} alt="logo" style={{ width: "65px", height: '65px', marginLeft:'5%' }} />
          <Box style={{ marginRight:5 }}>
            <Nav />
          </Box>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "88%",
            background:'#fff',
            overflowY:'auto',
          }}
        >
           
            <Box className={classes.VideoList}>
   <h2 style={{fontFamily:'Poppins', color:'#C7222A',fontSize:20,textAlign:'left',marginLeft:'5%', fontWeight:'600',marginTop:30,}}>Tutorials</h2>         
  <Box className={classes.VideoCol}>
      <Box className={classes.ImageCol}><img onClick={() => handleClickOpen(1)} src={face_poster} alt="logo" /></Box>
      <Box className={classes.VideoContent}>
          <h3>How to do a face scan?</h3>
          <p>Learn how a face scan works. It's as easy as taking a selfie.</p>   
      </Box>
           
  </Box>
  <Box className={classes.VideoCol}>
      <Box className={classes.ImageCol}><img onClick={() => handleClickOpen(2)} src={finger_poster} alt="logo" /></Box>
      <Box className={classes.VideoContent}>
          <h3>How to do a finger scan?</h3> 
          <p>Learn how to place your finger on the rear camera and the flashlight.</p> 
      </Box>
           
  </Box>
  
            </Box>
            <Dialog className={classes.Modal}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
        <VideoPlayer className={classes.VideoPlayer}
    url={videoURL+"#t=0.001"}
    autoplay
  />
        </DialogContent>
        
      </Dialog>
        </Box>
      </Box>
    </div>
  );
}

export default TutorialList;
const useStyles = makeStyles(() => ({
  Scan: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    background:'#fff',
    "& a": {
      textDecoration: "none",
    },
  },
  VideoContent:{
      width:'70%'
  },
  ModalClose:{
      position:'absolute',
      right:15,
      marginTop:5,
      width:40,
      minWidth:40,
      height:40,
      borderRadius:50,
      background:'#000',
      display:'flex',
      justifyContent:'center',
      alignItems:'center'
  },
  VideoCol:{
      width:'90%',
      padding:'5%',
      background:'#fff',
      borderBottom:'1px rgba(0,0,0,0.05) solid',
      display:'flex',
      alignItems:'center',
      '& p':{
          textAlign:'left',
          margin:0,
          fontFamily:'Poppins',
          color:'#666',
          fontSize: '12px'
      },
      '& h3':{
          fontSize:16,
          marginTop:0,
          marginBottom:0,
          textAlign:'left',
          fontFamily:'Poppins',
          fontWeight:'600'
      }
  },
  ImageCol:{
      width:'35%',
      marginRight:20,
      borderRadius:10,
      overflow:'hidden',
      display:'flex',
      height:70,
      '& img':{
          width:'100%'
      }
  },
  VideoList:{
      width:'100%',
      background:'#fff',
      padding:'0',
      
      '& .css-t2klrc':{
          width:'100%',
          height:'100%'
      }
  },
  Modal:{
      '& .MuiDialog-paper':{
          background:'none',
          boxShadow:'none',
          margin:0,
      },
      '& .MuiDialogContent-root':{
padding:10,
      },
    '& .css-t2klrc':{
        width:'100%',
        height:'100%'
    }
  },
  VideoPlayer:{
      width:'100%',
      height:'100%'
  },
  logo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "12%",
    background:'#fff',
    padding:'15px 0',
    borderBottom:'1px rgba(0,0,0,0.05) solid',
  },
  CancelBtn: {
    fontFamily: "Poppins",
    fontSize: 16,
    color: "#2F99F1",
    textTransform: "capitalize",
    padding: "0 20px",
    width: "142px",
    height: "50px",
    borderRadius: "50px",
  },
  
}));
