import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../Images/logo.png";
import camera from "../Images/camera-img.png";
import demo from "../Images/mocky.png";
import { Box } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Nav from "../Component/Nav";
import ReactPlayer from "react-player/youtube";

function Tutorial() {
  const classes = useStyles();
  const playerRef = useRef(null);
  const [playing, setPlaying] = useState(true);
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);
  return (
    <div>
      <Box className={classes.Scan}>
        <Box className={classes.logo}>
          <img src={logo} alt="logo" style={{ width: "65px", height: '65px', marginLeft: 25 }} />
          <Box style={{ marginRight: 15 }}>
            <Nav />
          </Box>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            width: "100%",
            height: "88%",
          }}
        >
          <Box className={classes.ScanCamera}>
            <h4 style={{ fontSize: 18, color: "#C7222A", textAlign: "center" }}>
              How it works?
            </h4>
            <video
              style={{ maxWidth: "100%", width: "800px", margin: "0 auto" }}
              playsInline
              loop
              muted
              controls
              alt="All the devices"
              src="https://stream.mux.com/6fiGM5ChLz8T66ZZiuzk1KZuIKX8zJz00/medium.mp4"
              ref={videoEl}
            />
          </Box>
          <Box className={classes.ScanInfo}>
            <Button className={classes.CancelBtn}>Skip</Button>
            <Button className={classes.Proced}>Proceed</Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default Tutorial;
const useStyles = makeStyles(() => ({
  Scan: {
    display: "flex",
    height: "100%",
    position: "fixed",
    left: 0,
    right: 0,
    flexDirection: "column",
    "& a": {
      textDecoration: "none",
    },
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  ScanInfo: {
    width: "100%",
    height: "30%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    paddingBottom: "15%",
  },
  ScanCamera: {
    width: "100%",
    height: "40%",
    marginTop: "15%",
  },
  logo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "12%",
  },
  CancelBtn: {
    fontFamily: "Poppins",
    fontSize: 16,
    color: "#C7222A",
    textTransform: "capitalize",
    padding: "0 20px",
    width: "142px",
    height: "50px",
    borderRadius: "50px",
  },
  Proced: {
    background: "linear-gradient(90deg, #C7222A 0%, #C7222A 100%)",
    padding: "0 20px",
    width: "142px",
    height: "50px",
    borderRadius: "50px",
    fontSize: 16,
    color: "#fff",
    marginTop: "0",
    textTransform: "capitalize",
    marginRight: 30,
    "&:hover": {
      background: "#333",
    },
  },
}));
