import React, {
  useEffect,
  Fragment,
  useCallback,
  useState,
  useRef,
} from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar, Line } from "react-chartjs-2";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../Images/logo.png";
import risk from "../Images/risk.png";
import heart from "../Images/heart.png";
import blood from "../Images/blood.png";
import hrv from "../Images/hrv.png";
import cardiac from "../Images/cardiac.png";
import hearthealth from "../Images/heart_health2.png";
import rpm from "../Images/rpm_ad.png";
import bodyvitals from "../Images/body_vitals.png";
import physio from "../Images/physio.png";
import stress from "../Images/stress.png";
import { Box } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import faceIcon from "../Images/face-scan.png";
import fingerIcon from "../Images/finger-scan.png";
import Nav from "../Component/Nav";
import GaugeChart from "react-gauge-chart";
import moment from "moment";
import { BsArrowLeftCircle } from "react-icons/bs";

import { apiAxios, apiAxios2 } from "../Util/ApiAxios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { MotionAnimate } from "react-motion-animate";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function ChartData(props) {
  const classes = useStyles();
  const [greetings, setGreetings] = React.useState("Welcome,");

  const [data, setData] = React.useState([]);
  const [label, setLabel] = React.useState([]);
  const [bgColor, setBgColor] = React.useState("");
  const user = JSON.parse(localStorage.getItem("user_details"));

  const [sys, setSys] = React.useState([]);
  const [dia, setDia] = React.useState([]);
  // const options = {};
  // const labels = [];
  // const dataset = {};

  useEffect(() => {
    //setUserDetails( JSON.parse(localStorage.getItem("user_details")) );

    getData();
  }, []);

  const getData = () => {

  

    let data = {
      employee_id: localStorage.getItem("emp_id"),
      vitals_type: props.vitals,
      date_range: props.range,
    };

    apiAxios2
      .post("/users/sdk/graph_vitals_history_private", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.id_token,
        },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.statusCode == 200) {
          let data_arr = [];
          let data_sys_arr = [];
          let data_dia_arr = [];
          let label_arr = [];

          if(props.type == "vitals"){
            if (
              props.vitals == "heart_rate" ||
              props.vitals == "oxy_sat_prcnt" ||
              props.vitals == "resp_rate"
            ) {
              let rg = response.data.response.response_graph;
              for (var i = 0; i < rg.length; i++) {
                data_arr[i] = Math.round(rg[i].vitals_data);
                label_arr[i] = moment(rg[i].scan_date).format("DD-MM");
              }
            } else if (props.vitals == "bp") {
              let rg = response.data.response.response_graph;
              for (var i = 0; i < rg.length; i++) {
                data_sys_arr[i] = Math.round(rg[i].bp_sys);
                data_dia_arr[i] = Math.round(rg[i].bp_dia);
                label_arr[i] = moment(rg[i].scan_date).format("DD-MM");
              }

              setSys(data_sys_arr);
              setDia(data_dia_arr);
            }
          }
          else if(props.type == "heart") {
              if(props.name == "sdnn") {
                let rg = response.data.response.response_graph;
                for (var i = 0; i < rg.length; i++) {
                  data_arr[i] = Math.round(rg[i].sdnn);
                  label_arr[i] = moment(rg[i].scan_date).format("DD-MM");
                }
              }
              else if(props.name == "co"){
                let rg = response.data.response.response_graph;
                for (var i = 0; i < rg.length; i++) {
                  data_arr[i] = Math.round(rg[i].cardiac_out);
                  label_arr[i] = moment(rg[i].scan_date).format("DD-MM");
                }
              }
              else if(props.name == "map"){
                let rg = response.data.response.response_graph;
                for (var i = 0; i < rg.length; i++) {
                  data_arr[i] = Math.round(rg[i].map);
                  label_arr[i] = moment(rg[i].scan_date).format("DD-MM");
                }
              }
              else {
                let rg = response.data.response.response_graph;
                for (var i = 0; i < rg.length; i++) {
                  data_arr[i] = Math.round(rg[i].vo2max);
                  label_arr[i] = moment(rg[i].scan_date).format("DD-MM");
                }
              }
          }

          let randomColor = Math.floor(Math.random() * 16777215).toString(16);
          //chart data
          setData(data_arr);
          setLabel(label_arr);

          setBgColor("#" + randomColor);
        } else {
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          //setShowLoader(false);
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        //setShowLoader(false);
      });
  };

  //   const getView = () =>{

  //     let dt = new Date();

  //     let data = {
  //         vital_action: "Heart Rate",
  //         employee_id: localStorage.getItem("emp_id"),
  //         table_config_rows_per_page: 1,
  //         table_config_page_no: 1,
  //         start_date: "01-01-2021",
  //         end_date: moment(dt).format("YYYY-MM-DD")
  //       };

  //         apiAxios2
  //         .post("/users/sdk/list_vitals_data_private", data, {
  //             headers: {
  //             "Content-Type": "application/json",
  //             "Authorization": user.id_token
  //             },
  //         })
  //         .then((response) => {
  //             console.log(response.data);
  //             if (response.data.statusCode == 200) {

  //                 setLatestData(response.data.data);

  //             } else {
  //             toast.error(response.data.message, {
  //                 position: "top-right",
  //                 autoClose: 5000,
  //                 hideProgressBar: false,
  //                 closeOnClick: true,
  //                 pauseOnHover: true,
  //                 draggable: true,
  //                 progress: undefined,
  //             });
  //              //setShowLoader(false);
  //             }
  //         })
  //         .catch((error) => {
  //             toast.error(error.message, {
  //             position: "top-right",
  //             autoClose: 5000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //             });
  //             //setShowLoader(false);
  //         });

  //   }

  return (
    <>
      <Box className={classes.chartBox}>
        { (props.type == "vitals") ?
        <h4>
          {props.vitals == "heart_rate"
            ? "Heart Rate"
            : props.vitals == "oxy_sat_prcnt"
            ? "Oxygen Saturation"
            : props.vitals == "resp_rate"
            ? "Respiration Rate"
            : props.vitals == "bp"
            ? "Blood Pressure"
            : ""
          }
        </h4>
        :
        (props.type == "heart") ?
        <h4>
          {props.name == "sdnn"
            ? "HRV (SDNN)"
            : props.name == "co"
            ? "Cardiac Output"
            : props.name == "map"
            ? "Mean Arterial Pressure"
            : "VO2Max"}
        </h4>
        :
        <></>
        }
        {data.length > 0 && label.length > 0 ? (
          <>
            <Line
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: "top",
                    display: false,
                  },
                  title: {
                    display: false,
                    text: "Chart.js Bar Chart",
                  },
                },
                scales: {
                  x: {
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    grid: {
                      display: false,
                    },
                  },
                },
                elements: {
                  line: {
                    tension: 0.6, // bezier curves
                  },
                },
              }}
              data={{
                labels: label,
                datasets: [
                  {
                    label: "Heart Rate",
                    data: data,
                    backgroundColor: "#C7222A",
                    borderWidth: 1.5,
                    pointRadius: 3,
                    borderColor: "#C7222A45",
                  },
                ],
              }}
            />
                        
            {/* <Link to="/history-details"> <p>View History</p></Link> */}

            <Link
              to={{
                pathname: "/history-details",
                state: {
                  vitals: props.vitals,
                  name: props.name ? props.name : "",
                  type: props.type
                },
              }}
            >
              {" "}
              <p>View History</p>
            </Link>
          </>
        ) : sys.length > 0 && dia.length > 0 && label.length > 0 ? (
          <>
            <Line
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: "top",
                    display: false,
                  },
                  title: {
                    display: false,
                    text: "Chart.js Bar Chart",
                  },
                },
                scales: {
                  x: {
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    grid: {
                      display: false,
                    },
                  },
                },
                elements: {
                  line: {
                    tension: 0.6, // bezier curves
                  },
                },
              }}
              data={{
                labels: label,
                datasets: [
                  {
                    label: "Heart Rate",
                    data: sys,
                    backgroundColor: "#C7222A",
                    borderWidth: 1.5,
                    pointRadius: 3,
                    borderColor: "#C7222A45",
                  },
                  {
                    label: "Heart Rate",
                    data: dia,
                    backgroundColor: "#0105ab",
                    borderWidth: 1.5,
                    pointRadius: 3,
                    borderColor: "#0105ab45",
                  },
                ],
              }}
            />
            {/* <Link to="/history-details"> <p>View History</p></Link> */}
                  
            <Link
              to={{
                pathname: "/history-details",
                state: {
                  vitals: props.vitals,
                  name: props.name ? props.name : "",
                  type: props.type
                },
              }}
            >
              {" "}
              <p>View History</p>
            </Link>
          </>
        ) : (
          <h5
            style={{
              textAlign: "center",
              margin: "5%",
              fontSize: "12px",
              fontWeight: "300",
            }}
          >
            No Data Found
          </h5>
        )}
      </Box>
    </>
  );
}

export default ChartData;
const useStyles = makeStyles(() => ({
  Scan: {
    display: "flex",
    height: "100vh",
    padding: "0 7.5%",
    left: 0,
    right: "0",
    background: "linear-gradient(180deg, #f4f4f5, white)",
    flexDirection: "column",
    "& a": {
      textDecoration: "none",
    },
  },
  chartBox: {
    padding: "2% 5%",
    marginTop: "5%",
    backgroundColor: "#fdfdfd",
    borderRadius: "20px",
    border: "1px solid #e0e3e5",
    "& h4": {
      fontSize: "1.2rem",
      fontWeight: "400",
      textAlign: "left",
      margin: "2% 0 8% 0",
      color: "#C7222A",
    },
    "& p": {
      textAlign: "right",
      fontSize: "0.8rem",
      color: "#C7222A",
    },
  },

  healthscorebox: {
    width: "100%",
    background: "linear-gradient(176deg, #eaf0f5 0%, #e9ecef 92.7%)",
    borderRadius: "10px",
    height: "20%",
  },
  scanbox: {
    height: "25%",
    width: "100%",
  },
  healthscoreboxleft: {
    width: "65%",
    float: "left",
    height: "100%",
    paddingTop: "5%",
  },
  histIcon: {
    width: "25%",
    textAlign: "center",
    float: "left",
  },
  histText: {
    fontFamily: "Poppins",
    fontSize: "0.8em",
    fontWeight: "300",
    margin: "0",
  },
  scanboxleft: {
    width: "45%",
    float: "left",
    textAlign: "left",
    "& p": {
      fontSize: "12px",
      color: "#000",
      fontFamily: "Poppins",
    },
  },
  healthscoreboxright: {
    width: "35%",
    color: "#1a2e5c",
    paddingTop: "12%",
    float: "left",
    textAlign: "left",
    height: "100%",
  },
  scanboxright: {
    width: "55%",
    float: "left",
    textAlign: "center",
  },
  riskbar: {
    padding: "10%",
    height: "2.5em",
    marginTop: "15%",
    borderRadius: "100%",
    border: "3px solid #fff",
  },
  subScan: {
    fontSize: "0.7em",
    color: "#5c6374",
    margin: "0",
    padding: "0 10%",
    fontFamily: "Poppins",
  },
  optionIcon: {
    width: "100%",
    textAlign: "center",
    height: "50%",
    "& h4": {
      fontFamily: "Poppins",
      color: "#C7222A",
      fontWeight: "400",
      fontSize: 16,
      marginTop: 10,
      marginBottom: 10,
    },
  },
  ScanInfo: {
    width: "100%",
    background: "linear-gradient(180deg, #C7222A -92.7%, #C7222A 92.7%)",
    height: "38vh",
    "& p": {
      fontFamily: "Poppins",
      color: "rgba(255,255,255,0.7)",
      fontWeight: "400",
      fontSize: 12,
      padding: "0 20px",
      marginTop: 0,
      marginBottom: 30,
      lineHeight: 1.6,
    },
    "& h4": {
      fontFamily: "Poppins",
      color: "rgba(255,255,255,0.8)",
      fontWeight: "400",
      fontSize: 16,
      marginTop: 20,
      marginBottom: 10,
    },
    "& h3": {
      fontSize: 16,
      fontFamily: "Poppins",
      color: "#fff",
      fontWeight: "400",
      marginTop: 40,
      marginBottom: 0,
      "& span": {
        fontSize: 24,
        fontWeight: "600",
        color: "#C7222A",
        marginRight: 5,
      },
    },
  },
  ScanCamera: {
    width: "100%",
    height: "49.7vh",
  },
  logo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "15%",
  },
  CancelBtn: {
    fontFamily: "Poppins",
    fontSize: 16,
    color: "#C7222A",
    textTransform: "capitalize",
    borderRadius: 50,
    padding: "5px 20px",
  },

  loginbtn: {
    background: "linear-gradient(90deg, #C7222A 0%, #C7222A 100%)",
    padding: "0 20px",
    width: "162px",
    height: "50px",
    borderRadius: "50px",
    color: "#fff",
    marginTop: "0",
    textTransform: "capitalize",
    boxShadow: "0 0 0 0 #2680db24",
    animation: "pulse 1.5s infinite",
  },
  name: {
    margin: 0,
    fontSize: "1.4em",
    fontFamily: "Poppins",
    fontWeight: "600",
  },
  greeting: {
    fontWeight: "300",
    color: "#C7222A",
    marginBottom: 0,
  },
  "@keyframes pulse": {
    "0%": {
      transform: "scale(.9)",
    },
    "70%": {
      transform: "scale(1)",
      boxShadow: "0 0 0 50px #2680db24",
    },
    "100%": {
      transform: "scale(.9)",
      boxShadow: "0 0 0 0 #2680db24",
    },
  },
}));
