import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../Images/logo.png";
import demo from "../Images/mocky.png";
import { Box } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Nav from "../Component/Nav";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { apiAxios2, API_KEY, SECRET_KEY } from "../Util/ApiAxios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loader from "../Images/loader.gif";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

function Survey(props) {
  const classes = useStyles();

  const [isloading, setIsLoading] = useState(true);
  const [surveyData, setSurveyData] = useState([]);
  const [responseArray, setResponse] = useState([]);
  const [dropOptions, setDropOption] = useState({});
  const [change, setChange] = useState(false);
  const [ansobject, setansObject] = useState(null);
  const [dropControl, setdropControl] = React.useState([]);
  const [answerError, setAnswerError] = useState(false);
  const [showLoader, setShowLoader] = React.useState(false);

  const [selected, setSelected] = React.useState([]);

  useEffect(() => {

    setShowLoader(true);
    getData();
  }, []);

  const getData = () => {
    let userDetails = JSON.parse(localStorage.getItem("user_details"));

    let data = {
      table_config_rows_per_page: 10,
      table_config_page_no: 1,
    };
    apiAxios2
      .post("/users/questions/list", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: userDetails.id_token,
        },
      })
      .then((response) => {
        let data = response.data.response.response_packages;
        let answerArray = [];
        setSurveyData(data);
        data.map((item, index) => {
          answerArray.push({ id: item.id, answer: "" });
        });
        setResponse(answerArray);
        setShowLoader(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangeSelected = (e, id, option = false) => {
    setAnswerError(false);
    let value = !option ? e.target.value : "yes_option";
    let answers = responseArray;
    let data = dropOptions;
    answers.forEach((item) => {
      if (item.id == id) {
        item.answer = value;
      }
    });
    data[id] = value === "yes_option";
    setDropOption(data);
    setResponse(answers);
    setChange(!change);
  };

  const handleDropDownChangeSelected = (e, id) => {
    setAnswerError(false);
    let value = e.target.value;
    let answers = responseArray;
    answers.forEach((item) => {
      if (item.id == id) {
        item.answer = value;
      }
    });
    setResponse(answers);
    setChange(!change);
  };

  const handleTextInput = (e, id) => {
    let value = e.target.value;
   
    
    setAnswerError(false);
    
    let answers = responseArray;
    answers.forEach((item) => {
      if (item.id == id) {
        item.answer = value;
      }
    });
    setResponse(answers);
    setChange(!change);
  };

  const [otherText, setOtherText] = React.useState("");
  const [otherFlag, setOtherFlag] = React.useState(false);

  const handleChange = (event) => {
    const value = event.target.value;
    setSelected(value);
    setAnswerError(false);
    
    
    let fl =0;

    for(var i=0;i<value.length;i++){
      if(value[i] == "Other"){
          fl = 1;
      }
    }

    if(fl == 0){
      setOtherText("");
      setOtherFlag(false);
    }
    else{
      setOtherFlag(true);
    } 
    
    console.log("Other Text = ", otherText);
    

  };

  useEffect(() => {
    let data = surveyData;
    data.forEach((item) => {
      let value;
      if (responseArray.find((i) => i.id == item.id)) {
        item.question_answers = value ? value : null;
      }
    });
    setSurveyData(data);
  }, [change]);

  function onUserSubmit() {
    let isValid = true;

    let data = [];

    let new_data = [];

    data = responseArray;


    console.log("response array -", responseArray);
    for(var i=0;i<data.length;i++){

      // checking for all white spaces in Emp ID field
      if(i==0){
      let str = data[i].answer.toString()
      if(str.replaceAll(' ','').length ==0) {
        setAnswerError(true)
        isValid = false
      }else {
       setAnswerError(false)
     }
    }
        
      
        if(i == 3){


          if(data[i] == "" || data[i] == undefined || data[i] == "Other" || data[i] == null){
            selected.splice(i,1);
          }

          if(otherText.length > 0){
            selected.push(otherText);
          }

          // let ans = "";
          // if(selected.length>0){
          //   for(var i=0;i<selected.length;i++){
          //     ans = ans + selected[i] + ",";
          //   }
          // }

          if(selected.length > 0 && selected[0] !="" && data[i].answer != 'no'){
            data[i].answer = selected;
          }
          else{

            if(data[i].answer == 'no'){
              data[i].answer = ['no'];
              setAnswerError(false);
            }
            else{
              setAnswerError(true);
              isValid = false;
            }
            

          }
          
         
          
        }

        if(data[i].answer == "yes_option"){
          data[i].answer = "yes";
        }

        new_data.push(data[i]);
        console.log("item = ", data[i]);

        if(data[i].answer == "" || data[i].answer == " " || data[i].answer === null){
          setAnswerError(true);
          isValid = false;
        }
        else{
          setAnswerError(false);
        }
        
       
      
    }



    console.log("DATA = ", new_data);

    for(var i=0; i< data.length;i++){
      if(data[i].answer){
      }
      else{
        setAnswerError(true);
        isValid = false;
      }
    }

    

    if (isValid) {
      let userDetails = JSON.parse(localStorage.getItem("user_details"));
      let sendData = {
        answers: new_data,
      };

      apiAxios2
        .post("/users/questions/survey-answer", sendData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: userDetails.id_token,
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.statusCode == 200) {
            toast.success(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            props.history.push("/dashboard");
          } else {
            toast.error(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error(error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } else {
      setAnswerError(true);
      setSelected([]);
      setOtherText("");
    }
  }

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Box className={classes.Survey}>
        <Box className={classes.logo}>
          <img src={logo} alt="logo" style={{ height:'65px', width: '65px'}} />
          <Box style={{ marginRight: -15 }}>
            <Nav />
          </Box>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
            width: "100%",
            height: "68vh",
            marginTop: "3vh",
          }}
        >
          <h3
            style={{
              fontFamily: "Poppins",
              fontSize: 20,
              fontWeight: "500",
              textAlign: "left",
              color: "#C7222A",
              paddingLeft: 10,
            }}
          >
            We Need Few More Details
          </h3>
          
          { showLoader ?
          <Box style={{width:'100%', textAlign: 'center', padding: '5% 0'}}>
          <img src={loader} alt="loader" style={{width:'50px',height:'auto'}} />
          </Box>
          :  
          <List component="nav">
            {surveyData
              ? surveyData.map((survey) => (
                  <ListItem
                    button
                    className={classes.surveyList}
                    key={survey.id}
                  >
                    <Box className={classes.QuestionRow}>
                      <h3>
                        {survey.question.charAt(0).toUpperCase() +
                          survey.question.slice(1)}
                      </h3>
                    </Box>
                    {survey.question_options?.length > 0 ? (
                      <Box>
                        <FormControl component="fieldset">
                            {(survey.question_options?.length == 2 || survey.question_type == 4) ?
                              <RadioGroup
                              row
                              aria-label="position"
                              name="position"
                              defaultValue="top"
                            >
                              
                              <FormControlLabel
                                value="yes"
                                control={
                                  <Radio
                                    color="primary"
                                    selected={responseArray.find((i) => {
                                      if (survey.id == i.id) {
                                        i.answer == "yes" ||
                                          i.answer == "yes_option";
                                      }
                                    })}
                                    onChange={(e) => {
                                      if (
                                        survey.question_order == 3 ||
                                        survey.question_order == 4
                                      ) {
                                        handleChangeSelected(e, survey.id, true);
                                      } else {
                                        handleChangeSelected(e, survey.id);
                                      }
                                    }}
                                  />
                                }
                                label="Yes"
                              />
                              <FormControlLabel
                                value="no"
                                control={
                                  <Radio
                                    color="primary"
                                    selected={responseArray.find((i) => {
                                      if (survey.id == i.id) {
                                        i.answer == "no";
                                      }
                                    })}
                                    onChange={(e) =>
                                      handleChangeSelected(e, survey.id)
                                    }
                                  />
                                }
                                label="No"
                              />
                            </RadioGroup>
                            :
                            <RadioGroup
                              row
                              aria-label="position"
                              name="position"
                              defaultValue="top"
                            >
                              { survey.question_options.map((question) => (
                              <FormControlLabel
                                value={question}
                                control={
                                  <Radio
                                    color="primary"
                                    selected={responseArray.find((i) => {
                                      if (survey.id == i.id) {
                                        i.answer == {question};
                                      }
                                    })}
                                    onChange={(e) =>
                                      handleChangeSelected(e, survey.id)
                                    }
                                  />
                                }
                                label={question}
                              />
                              ))}
                            </RadioGroup>
                          }

                          
                          
                        </FormControl>
                      </Box>
                    ) : (
                      <TextField
                        className={classes.input}
                        placeholder="Your answer"
                        type="text"
                        onChange={(e) => handleTextInput(e, survey.id)}
                      />
                    )}
                    <Box>
                      {dropOptions[survey.id] &&
                        (survey.question_order == 4) && (
                          <>
                          <Select
                            labelId="mutiple-select-label"
                            multiple
                            value={selected}
                            onChange={handleChange}
                            className={classes.input}
                            style={{maxWidth: '300px', minWidth: '300px'}}
                            renderValue={(selected) => selected.join(", ")}
                          >
                            {survey.question_options.map((option) => (
                              <MenuItem key={option} value={option}>
                                <ListItemIcon>
                                  <Checkbox checked={selected.indexOf(option) > -1} />
                                </ListItemIcon>
                                <ListItemText primary={option} />
                              </MenuItem>
                            ))}

                            { otherFlag ?
                                  <TextField
                                    className={classes.input}
                                    style={{maxWidth: '300px', minWidth: '300px', padding: '6px 16px'}}
                                    placeholder="Please Specify"
                                    type="text"
                                    onChange={(e) => setOtherText(e.target.value)}
                                    defaultValue = {otherText}
                                  />
                                  :
                                  <></>
                            } 

                          </Select>
                          
                        </>
                        )}
                    </Box>
                  </ListItem>
                ))
              : null}
          </List>
          }

          {answerError ? (
            <p
              style={{
                color: "#dc3545",
                fontSize: ".875em",
                marginTop: "0.15rem",
                marginBottom: "1rem",
                textAlign: "center",
              }}
            >
              All answers are mandatory
            </p>
          ) : (
            <></>
          )}

          <Box>
            <Button
              size="large"
              className={classes.loginbtn}
              onClick={onUserSubmit}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default Survey;
const useStyles = makeStyles(() => ({
  Survey: {
    display: "flex",
    flexDirection: "column",
    width: "90%",
    padding: "30px 5% 0",
    "& a": {
      textDecoration: "none",
    },
    "& .MuiListItem-button:hover": {
      background: "rgba(47, 153, 241, 0.1)",
    },
  },
  loginbtn: {
    background: "linear-gradient(90deg, #C7222A 0%, #C7222A 100%)",
    padding: "0 20px",
    width: "94%",
    height: "50px",
    borderRadius: "50px",
    color: "#fff",
    marginTop: "0",
    textTransform: "capitalize",
    "&:hover": {
      background: "#333",
    },
  },
  input: {
    border: "none",
    borderRadius: "10px",
    height: "45px",
    width: "100%",
    marginBottom: "10px",
    marginTop: 10,
  },
  loginform: {
    width: "100%",
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "45px",
    },
    "& .MuiInputBase-input": {
      height: "45px",
      borderRadius: "10px",
      background: "#fff",
      padding: "0 15px",
      border: "1px solid #C7222A",
    },
    "& .MuiInput-input:focus": {
      border: "1px #7087A7 solid",
      boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    },
  },
  QuestionRow: {
    display: "flex",
    alignItems: "center",
    "& h5": {
      fontFamily: "Poppins",
      fontSize: 16,
      fontWeight: "600",
      marginRight: 10,
      margin: 0,
      color: "#C7222A",
    },
  },
  surveyList: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column",
    borderRadius: 10,
    paddingLeft: 10,
    marginBottom: 15,
    "& h3": {
      fontSize: 18,
      color: "#000000",
      margin: 0,
      fontFamily: "Poppins",
      fontWeight: "600",
    },
    "& h4": {
      fontSize: 20,
      color: "#C7222A",
      margin: 0,
      fontFamily: "Poppins",
      lineHeight: "22px",
      fontWeight: "400",
      textAlign: "center",
    },
    "& p": {
      color: "#5C5C5C",
      fontSize: 14,
      margin: 0,
      fontFamily: "Poppins",
      textAlign: "center",
    },
  },

  logo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "12%",
  },
}));
