let stream, FPS_count;
let dataPointsHue = [];
let ppgSignal = [];
let redFrames = [];
let blueFrames = [];
let frameCount = 0;
let timeDiff = 0;
let sumred = 0;
let sumblue = 0;
let fmesh;
let video;
let videoWidth, videoHeight;
let videoDataSum;
let avgIntensity;
let tmp,
  fftData,
  curPollFreq,
  binNumber,
  binHz,
  maxVal,
  maxHz,
  maxInd,
  HzData,
  indexedData;
let avg_NN;
let percent = 0;
let counter1 = 0,
  counter2 = 0;

let setRawTemp;
let setTimeTemp;
let setFPSCountTemp;

let raw = [];
let ppg_time2 = [];

async function setupCamera() {
  video = document.getElementById("video");
  stream = await navigator.mediaDevices.getUserMedia({
    audio: false,
    video: {
      facingMode: "user",
      aspectRatio: 1.333,
      width: { ideal: 1280 }
    },
  });
  video.srcObject = stream;
  return new Promise((resolve) => {
    video.onloadedmetadata = () => {
      resolve(video);
    };
  });
}

async function setupFrontCamera() {
  video = document.getElementById("video");
  stream = await navigator.mediaDevices.getUserMedia({
    audio: false,
    video: {
      facingMode: "environment",
      // aspectRatio: 1.333,
      // width: { ideal: 1280 },
    },
  });
  video.srcObject = stream;
  if (stream) {
    const track = stream.getVideoTracks()[0];

    video.addEventListener("loadedmetadata", () => {
      window.setTimeout(
        () => onCapabilitiesReady(track.getCapabilities()),
        500
      );
    });
    return new Promise((resolve) => {
      video.onloadedmetadata = () => {
        resolve(video);
      };
    });
  }
}

function onCapabilitiesReady(capabilities) {
  if (capabilities.torch) {
    const track = stream.getVideoTracks()[0];
    track
      .applyConstraints({
        advanced: [{ torch: true }],
      })
      .catch((e) => console.log(e));
  }
}

var curFaces;
// Calls face mesh on the video and outputs the eyes and face bounding boxes to global vars
async function renderPrediction() {
  const facepred = await fmesh.estimateFaces(canvas);
  ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
  if (facepred.length > 0 && timeDiff <= 60000) {
    // If we find a face, process it
    curFaces = facepred;
    counter1++;
    await drawFaces();
  }
  if (timeDiff <= 60000) {
    requestAnimationFrame(renderPrediction);
  }
}

async function renderFingerPrediction() {
  ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
  if (timeDiff <= 60000) {
    await getFingerIntensities();
  }
  requestAnimationFrame(renderFingerPrediction);
}

async function getFingerIntensities() {
  let bloodRegion = ctx.getImageData(10, 10, canvas.width, canvas.height);
  //calculating RGB average intensities from image frame
  avgRGB = calcRGB(bloodRegion);
  let hsv = new Array(3).fill(0);
  if (avgRGB.r > 0) {
    hsv = RGBtoHSV(avgRGB.r, avgRGB.g, avgRGB.b, hsv);
    dataPointsHue.push(hsv[0]);
    redFrames.push(avgRGB.r);
    blueFrames.push(avgRGB.b);
    sumblue += avgRGB.b;
    sumred += avgRGB.r;
    frameCount++;
  }
  document.body.style.backgroundColor = "rgb(" + avgRGB.r + ",0,0)";

  raw_intensity.push(avgRGB.r.toFixed(2));

  // Get the area into Tensorflow, then split it and average the green channel
  videoDataSum = bloodRegion.data.reduce((a, b) => a + b, 0);
  videoDataSum -= 10 * 10 * 255; // remove alpha channel
  //avgIntensity = videoDataSum/(boxWidth*boxHeight*3);
  avgIntensity = avgRGB.r;
  // Get FPS of this loop as well
  timingHist.push(1 / ((performance.now() - last) * 0.001));
  last = performance.now();

  // Append intensity and FPS to an array and shift it out if too long
  //raw_intensity.push(avgIntensity);
  ppg.push(bloodHist[maxHistLen - 1] * 0.8 + 0.2 * avgIntensity);
  ppg_time.push(new Date() - start_time);
  bloodHist.push(bloodHist[maxHistLen - 1] * 0.8 + 0.2 * avgIntensity);
  if (bloodHist.length > maxHistLen) {
    bloodHist.shift();
    timingHist.shift();
    fftData = await calcFFT(bloodHist);
  }
}
//        At around 10 Hz for the camera, we want like 5 seconds of history
var maxHistLen = 64;
var raw_intensity = [];
var ppg = [];
var ppg_time = [];
var last_time = new Date();
var peak_time = new Date();
var start_time = new Date();
var bloodHist = Array(maxHistLen).fill(0);
var timingHist = Array(maxHistLen).fill(0);
var last = performance.now();
var avgRGB;
let boxLeft, boxTop, boxWidth, boxHeight;

var average = (array) => array.reduce((a, b) => a + b) / array.length;
// Draws the current eyes onto the canvas, directly from video streams
async function drawFaces() {
  ctx.strokeStyle = "#C7222A";
  ctx.lineWidth = 2;
  for (face of curFaces) {
    if (face.faceInViewConfidence > 0.9) {
      let mesh = face.scaledMesh;

      // Get the facial region of interest's bounds
      boxLeft = mesh[117][0];
      boxTop = mesh[117][1];
      boxWidth = mesh[346][0] - boxLeft;
      boxHeight = mesh[164][1] - boxTop;

      // Draw the box a bit larger for debugging purposes
      ctx.beginPath();
      const boxsize = 4;
      ctx.rect(
        boxLeft - boxsize,
        boxTop - boxsize,
        boxWidth + boxsize * 2,
        boxHeight + boxsize * 2
      );
      ctx.stroke();

      // Get the image data from that region
      let bloodRegion = ctx.getImageData(boxLeft, boxTop, boxWidth, boxHeight);

      //calculating RGB average intensities from image frame
      avgRGB = calcRGB(bloodRegion);
      let hsv = new Array(3).fill(0);
      if (avgRGB.r > 0) {
        hsv = RGBtoHSV(avgRGB.r, avgRGB.g, avgRGB.b, hsv);
        dataPointsHue.push(hsv[0]);
        redFrames.push(avgRGB.r);
        blueFrames.push(avgRGB.b);
        sumblue += avgRGB.b;
        sumred += avgRGB.r;
        frameCount++;
        raw.push(avgRGB.r);

        let td = new Date() - start_time;
        ppg_time2.push(td);
      }

      //document.body.style.backgroundColor = "rgb(" + avgRGB.r + ",0,0)";
      // console.log("RGB = ", avgRGB);
      raw_intensity.push(avgRGB.r.toFixed(2));

      // Get the area into Tensorflow, then split it and average the green channel
      videoDataSum = bloodRegion.data.reduce((a, b) => a + b, 0);
      videoDataSum -= boxWidth * boxHeight * 255; // remove alpha channel
      //avgIntensity = videoDataSum/(boxWidth*boxHeight*3);
      avgIntensity = avgRGB.r;
      // Get FPS of this loop as well
      timingHist.push(1 / ((performance.now() - last) * 0.001));
      last = performance.now();

      // Append intensity and FPS to an array and shift it out if too long
      //raw_intensity.push(avgIntensity);
      ppg.push(bloodHist[maxHistLen - 1] * 0.8 + 0.2 * avgIntensity);
      ppg_time.push(new Date() - start_time);
      bloodHist.push(bloodHist[maxHistLen - 1] * 0.8 + 0.2 * avgIntensity);
      if (bloodHist.length > maxHistLen) {
        bloodHist.shift();
        timingHist.shift();
        fftData = await calcFFT(bloodHist);
      }
    }
  }
}

function minVal(a, b) {
  return a < b ? a : b;
}

function maximumVal(a, b) {
  return a > b ? a : b;
}

function RGBtoHSV(r, g, b, hsv) {
  let min, max, delta;
  min = minVal(r, minVal(g, b));
  max = maximumVal(r, maximumVal(g, b));
  hsv[2] = max;
  delta = max - min;
  if (max != 0) hsv[1] = delta / max;
  else {
    hsv[1] = 0;
    hsv[0] = -1;
    return hsv;
  }
  if (r == max) hsv[0] = (g - b) / delta;
  else if (g == max) hsv[0] = 2 + (b - r) / delta;
  else hsv[0] = 4 + (r - g) / delta;
  hsv[0] *= 60;
  if (hsv[0] < 0) hsv[0] += 360;

  return hsv;
}

async function calcFFT(data) {
  // Remove offset
  const avg = average(data);
  data = data.map((elem) => elem - avg);
  // Calculate FFT
  tmp = fft.forward(data);
  // Remove DC term (should be 0 anyway) and return
  return tmp.slice(1);
}

function calcRGB(image) {
  counter2++;
  var blockSize = 5, // only visit every 5 pixels
    canvas = document.createElement("canvas"),
    i = -4,
    length,
    rgb = { r: 0, g: 0, b: 0 },
    count = 0;

  length = image.data.length;

  while ((i += blockSize * 4) < length) {
    ++count;
    rgb.r += image.data[i];
    rgb.g += image.data[i + 1];
    rgb.b += image.data[i + 2];
  }

  // ~~ used to floor values
  rgb.r = rgb.r / count;
  rgb.g = rgb.g / count;
  rgb.b = rgb.b / count;

  return rgb;
}

var heartrate = 0;
var hr_array = [];


var canvas;
var ctx;
var fft;

export async function face(setRaw, setTime, setFPSCount) {
  fmesh = await facemesh.load({ maxFaces: 1 });

  document.getElementById("time").innerHTML =
      "Opening Camera..";
      document.getElementById("sub_text").innerHTML = "...";

  setRawTemp = setRaw;
  setTimeTemp = setTime;
  setFPSCountTemp = setFPSCount;
  // Set up front-facing camera
  await setupCamera();
  videoWidth = video.videoWidth;
  videoHeight = video.videoHeight;
  video.play();
  //video.requestVideoFrameCallback(drawingLoop);
  // Create canvas and drawing context
  canvas = document.getElementById("faceoutput");
  canvas.width = videoWidth / 2;
  canvas.height = videoHeight / 2;
  ctx = canvas.getContext("2d");

  // Init the FFT objects
  fft = new window.kiss.FFTR(maxHistLen);

  // start prediction loop
  renderPrediction();
  document.getElementById("faceoutput").style.display = "block";
  document.getElementById("instruction").style.display = "none";
}


