let stream, FPS_count;
let dataPointsHue = [];
let ppgSignal = [];
let redFrames = [];
let blueFrames = [];
let frameCount = 0;
let timeDiff = 0;
let sumred = 0;
let sumblue = 0;
let fmesh;
let video;
let videoHeight;
let videoWidth;
let face;
let boxLeft, boxTop, boxWidth, boxHeight;
let videoDataSum;
let avgIntensity;
let tmp, fftData;
async function setupCamera() {
  video = document.getElementById("video");
  stream = await navigator.mediaDevices.getUserMedia({
    audio: false,
    video: {
      facingMode: "user",
      aspectRatio: 1.333,
      width: { ideal: 1280 },
    },
  });
  video.srcObject = stream;
  start_time = new Date();
  return new Promise((resolve) => {
    video.onloadedmetadata = () => {
      resolve(video);
    };
  });
}

var curFaces;
// Calls face mesh on the video and outputs the eyes and face bounding boxes to global vars
async function renderPrediction() {
  let facepred = [];
  if (canvas) {
    facepred = await fmesh.estimateFaces(canvas);
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
  }

  let now = new Date();
  timeDiff = now - start_time;

  if (timeDiff >= 20000) {
    let percentage = (timeDiff - 20000) / 40000;
    document.getElementById("time").innerHTML =
      "" + Math.round(percentage * 100) + "% Completed";
    document.getElementById("sub_text").innerHTML = "Scan in progress...";
  } else if (timeDiff < 20000) {
    document.getElementById("time").innerHTML = "Calibration in progress...";
    document.getElementById("sub_text").innerHTML =
      "Scan starts in " + (20 - Math.round(timeDiff / 1000)) + "s";
  }

  if (facepred.length > 0 && timeDiff <= 60000) {
    // If we find a face, process it
    curFaces = facepred;
    await drawFaces();
  }
  if (timeDiff > 60000) {
    stream.getTracks().forEach(function (track) {
      track.stop();
    });
    //canvasOutput = null;
    document.getElementById("time").innerHTML = "Scan Completed";

    hr_array.sort();
    var final_hr = 0;
    if (hr_array.length % 2 == 0) {
      final_hr = hr_array[hr_array.length / 2];
    } else {
      final_hr =
        (hr_array[(hr_array.length - 1) / 2] +
          hr_array[(hr_array.length + 1) / 2]) /
        2;
    }

    document.getElementById("final_hr").innerHTML = final_hr + " bpm";
    let raw_size = raw_intensity.length;
    setFPSCountTemp(Math.round(raw_size / 40));
    setTimeTemp(ppg_time);
    setRawTemp(raw_intensity);
  }

  requestAnimationFrame(renderPrediction);
}

//        At around 10 Hz for the camera, we want like 5 seconds of history
var maxHistLen = 64;
var fft_full = [];
var raw_intensity = [];
var ppg = [];
var ppg_time = [];
var last_time = new Date();
var peak_time = new Date();
var start_time;
var bloodHist = Array(maxHistLen).fill(0);
var timingHist = Array(maxHistLen).fill(0);
var last = performance.now();
var avgRGB;
var average = (array) => array.reduce((a, b) => a + b) / array.length;
var argMax = (array) =>
  array.map((x, i) => [x, i]).reduce((r, a) => (a[0] > r[0] ? a : r))[1];
// Draws the current eyes onto the canvas, directly from video streams
async function drawFaces() {
  ctx.strokeStyle = "#C7222A";
  ctx.lineWidth = 2;
  for (face of curFaces) {
    if (face.faceInViewConfidence > 0.9) {
      let mesh = face.scaledMesh;

      // Get the facial region of interest's bounds
      boxLeft = mesh[117][0];
      boxTop = mesh[117][1];
      boxWidth = mesh[346][0] - boxLeft;
      boxHeight = mesh[164][1] - boxTop;

      // Draw the box a bit larger for debugging purposes
      ctx.beginPath();
      const boxsize = 4;
      ctx.rect(
        boxLeft - boxsize,
        boxTop - boxsize,
        boxWidth + boxsize * 2,
        boxHeight + boxsize * 2
      );
      ctx.stroke();

      // Get the image data from that region
      let bloodRegion = ctx.getImageData(boxLeft, boxTop, boxWidth, boxHeight);

      //calculating RGB average intensities from image frame
      avgRGB = calcRGB(bloodRegion);
      let hsv = new Array(3).fill(0);
      if (avgRGB.r > 0) {
        hsv = RGBtoHSV(avgRGB.r, avgRGB.g, avgRGB.b, hsv);
        dataPointsHue.push(hsv[0]);
        redFrames.push(avgRGB.r);
        blueFrames.push(avgRGB.b);
        sumblue += avgRGB.b;
        sumred += avgRGB.r;
        frameCount++;
      }

      raw_intensity.push(avgRGB);
      ppg_time.push(new Date() - start_time);
      // Get the area into Tensorflow, then split it and average the green channel
      videoDataSum = bloodRegion.data.reduce((a, b) => a + b, 0);
      videoDataSum -= boxWidth * boxHeight * 255; // remove alpha channel
      //avgIntensity = videoDataSum/(boxWidth*boxHeight*3);
      avgIntensity = avgRGB.r;
      // Get FPS of this loop as well
      timingHist.push(1 / ((performance.now() - last) * 0.001));
      last = performance.now();

      // Append intensity and FPS to an array and shift it out if too long
      //raw_intensity.push(avgIntensity);
      ppg.push(bloodHist[maxHistLen - 1] * 0.8 + 0.2 * avgIntensity);
      
      bloodHist.push(bloodHist[maxHistLen - 1] * 0.8 + 0.2 * avgIntensity);
      if (bloodHist.length > maxHistLen) {
        bloodHist.shift();
        timingHist.shift();

        fftData = await calcFFT(bloodHist);
      }
    }
  }
}

function minVal(a, b) {
  return a < b ? a : b;
}

function maximumVal(a, b) {
  return a > b ? a : b;
}

function RGBtoHSV(r, g, b, hsv) {
  let min, max, delta;
  min = minVal(r, minVal(g, b));
  max = maximumVal(r, maximumVal(g, b));
  hsv[2] = max;
  delta = max - min;
  if (max != 0) hsv[1] = delta / max;
  else {
    hsv[1] = 0;
    hsv[0] = -1;
    return hsv;
  }
  if (r == max) hsv[0] = (g - b) / delta;
  else if (g == max) hsv[0] = 2 + (b - r) / delta;
  else hsv[0] = 4 + (r - g) / delta;
  hsv[0] *= 60;
  if (hsv[0] < 0) hsv[0] += 360;

  return hsv;
}

async function calcFFT(data) {
  // Remove offset
  const avg = average(data);
  data = data.map((elem) => elem - avg);

  // Calculate FFT
  tmp = fft.forward(data);

  // Remove DC term (should be 0 anyway) and return
  return tmp.slice(1);
}

function calcRGB(image) {
  var blockSize = 5, // only visit every 5 pixels
    defaultRGB = { r: 0, g: 0, b: 0 }, // for non-supporting envs
    canvas = document.createElement("canvas"),
    context = canvas.getContext && canvas.getContext("2d"),
    data,
    width,
    height,
    i = -4,
    length,
    rgb = { r: 0, g: 0, b: 0 },
    count = 0;

  length = image.data.length;

  while ((i += blockSize * 4) < length) {
    ++count;
    rgb.r += image.data[i];
    rgb.g += image.data[i + 1];
    rgb.b += image.data[i + 2];
  }

  // ~~ used to floor values
  rgb.r = rgb.r / count;
  rgb.g = rgb.g / count;
  rgb.b = rgb.b / count;

  return rgb;
}

var heartrate = 0,
  co = 0;
var nn = 0;
var sp_hr = 0;
var hr_array = [];

var canvas;
var ctx;
var fft;
let setRawTemp;
let setTimeTemp;
let setFPSCountTemp;
export async function faceiOS(
  setRaw,
  setTime,
  setFPSCount,
  setVideoHeight,
  setVideoWidth
) {
  setFPSCountTemp = setFPSCount;
  setTimeTemp = setTime;
  setRawTemp = setRaw;
  fmesh = await facemesh.load({ maxFaces: 1 });

  // Set up front-facing camera
  await setupCamera();
  videoWidth = video.videoWidth;
  videoHeight = video.videoHeight;
  video.play();

  // Create canvas and drawing context
  canvas = document.getElementById("canvasOutput");
  if (canvas) {
    canvas.width = videoWidth / 2;
    canvas.height = videoHeight / 2;
    ctx = canvas.getContext("2d");
  }

  renderPrediction();

  // Init the FFT objects
  fft = new window.kiss.FFTR(maxHistLen);

  // start prediction loop

  document.getElementById("canvasOutput").style.display = "block";
  document.getElementById("instruction").style.display = "none";
}
