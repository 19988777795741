import React, {
  useEffect,
  Fragment,
  useCallback,
  useState,
  useRef,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../Images/logo.png';
import demo from '../Images/mocky.png';
import { Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Nav from '../Component/Nav';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { API_KEY } from '../Util/ApiAxios';
import { apiAxios, apiAxios2 } from "../Util/ApiAxios";
import moment from 'moment';

function Report() {
  const classes = useStyles();

  const [reportList, setReportList] = React.useState([]);
  const [generatingFlag, setGeneratingFlag] = React.useState(false);

  useEffect(() => {
    getReports();
  }, []);

  const getReports = () => {
    let userDetails = JSON.parse(localStorage.getItem("user_details"));

    let data = {
      api_key: API_KEY,
      employee_id: localStorage.getItem("emp_id"),
    };
    apiAxios2
      .post("/report/list", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: userDetails.id_token,
        },
      })
      .then((response) => {

        if (response.data.statusCode == 200) {
          setReportList(response.data.data);
          setGeneratingFlag(false);
        }


      })
      .catch((error) => {
        console.log(error);
      });
  };


const generateReport = () => {

  let scan_id = "";
  if (localStorage.getItem("latest_scan")) {
    scan_id = localStorage.getItem("latest_scan");
  }

  let userDetails = JSON.parse(localStorage.getItem("user_details"));

  let data = {
    api_key: API_KEY,
    employee_id: localStorage.getItem("emp_id"),
    last_scan_id: scan_id
  };
  apiAxios2
    .post("/report/add", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: userDetails.id_token,
      },
    })
    .then((response) => {

      if (response.data.statusCode == 200) {
        setGeneratingFlag(true);

        setTimeout(function () {
          getReports();
        }, 25000);

      }

    })
    .catch((error) => {
      console.log(error);
    });
};




return <div>
  <Box className={classes.Report}>
    <Box className={classes.logo}>
      <img src={logo} alt="logo" style={{ width: "65px", height: '65px', marginLeft: '5%' }} />
      <Box style={{ marginRight: 5 }}>
        <Nav />
      </Box>
    </Box>
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "88%",
        background: '#fff',
        overflowY: 'auto',
      }}
    >
      <Box className={classes.ReportTop}>
        <h2 style={{ fontFamily: 'Poppins', color: '#C7222A', fontSize: 20, textAlign: 'left', fontWeight: '600', paddingLeft: '5%', marginTop: 30, }}>Reports</h2>
        { localStorage.getItem("latest_scan") ? 
        <>
          { generatingFlag ?
          <p>Generating...</p>
          :
          <Button className={classes.ReportBtn} onClick={generateReport}>Generate Report</Button>
          }
        </>
        :
        <Button className={classes.ReportBtn} style={{backgroundColor: '#aeaeae', opacity: '0.4'}}>Generate Report</Button>
        }
      </Box>
      {reportList.length > 0 ?
        <>
          {reportList.map((each) => (
            <Box className={classes.ReportTable}>
              <Box className={classes.ReportRow}>
                <Box>
                  <p>Scan Time</p>
                  <h6>{moment.utc(each.created_at, "YYYY-MM-DD HH:mm").local().format("DD-MM-YYYY HH:mm a")}</h6>
                </Box>
                {each.status == 1 ?  
                <a href={"https://www.files.corporate.careplix.com/"+each.report_location}>
                  <Button className={classes.DownloadBtn}>Download Report</Button>
                </a>
                :
                <p>Not yet generated</p>
                }
              </Box>
            </Box>
          ))}
        </>
        :
        <Box style={{ textAlign: 'center' }}>No Data Found</Box>
      }
    </Box>
  </Box>
</div>;
}

export default Report;
const useStyles = makeStyles(() => ({
  Report: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    background: '#fff',
    "& a": {
      textDecoration: "none",
    },
  },
  ReportTable: {
    width: '100%',
    paddingBottom: 20
  },
  DownloadBtn: {
    fontFamily: "Poppins",
    fontSize: 15,
    textTransform: "capitalize",
    color: '#C7222A'
  },
  ReportRow: {
    width: '90%',
    padding: '10px 5%',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px rgba(0,0,0,0.07) solid',
    '& p': {
      fontFamily: 'Poppins',
      fontSize: 16,
      color: '#3698ef',
      textAlign: 'left',
      fontWeight: '600',
      margin: 0
    },
    '& h6': {
      fontFamily: 'Poppins',
      fontSize: 15,
      color: '#1d1d1d',
      textAlign: 'left',
      fontWeight: '600',
      margin: 0,
      '& span': {
        marginLeft: 5,
      }
    },
    '&:nth-child(even)': {
      background: '#fff'
    },
    '&:nth-child(odd)': {
      background: '#ebebeb'
    },
  },
  logo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "12%",
    background: '#fff',
    padding: '15px 0',
    borderBottom: '1px rgba(0,0,0,0.05) solid',
  },
  ReportBtn: {
    fontFamily: "Poppins",
    fontSize: 15,
    color: "#fff",
    textTransform: "capitalize",
    padding: "0 20px",
    height: "50px",
    borderRadius: "50px",
    background: '#C7222A',
  },
  ReportTop: {
    width: '90%',
    padding: '0 5%',
    marginBottom: 15,
    marginTop: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h3': {
      fontFamily: 'Poppins',
      fontSize: 20,
    }
  }
}));