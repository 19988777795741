import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../Images/logo.png';
import demo from '../Images/mocky.png';
import { Box, Hidden } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import loader from '../Images/loader.gif';

import { apiAxios, apiAxios2, API_KEY } from "../Util/ApiAxios";
import { useState, useEffect } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { MotionAnimate } from 'react-motion-animate'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Email from '../Images/mail.svg';
import Call from '../Images/accept-call.svg';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function Login(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  function Mailto({ email, subject, body, ...props }) {
    return (
      <a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
        {props.children}
      </a>
    );
  }
  const Callto = ({ phone, children }) => {
    return <a href={`tel:${phone}`}>{children}</a>;
  };
  const [phone, setPhone] = React.useState("");
  const [phoneError, setPhoneError] = React.useState(false);
  const [clientCode, setClientCode] = React.useState("");
  const [clientCodeError, setClientCodeError] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(false);

  useEffect(() => {

    localStorage.removeItem("phone");

    if (localStorage.getItem("user_details")) {
      props.history.push("/dashboard");
    }

  }, []);

  function onUserSubmit(e) {

    e.preventDefault();

    let isValid = true;


    localStorage.removeItem("Token");

    if (phone.length == 10) {
      setPhoneError(false);
    }
    else {
      setPhoneError(true);
      isValid = false;
    }


    if (isValid) {

      setShowLoader(true);

      let data = {
        action: "send",
        mobile_no: phone,
        channel_id: "login_mobile",
        email: "",
        api_key: API_KEY
      };


      localStorage.setItem("api_key", clientCode);

      apiAxios2
        .post("/auth/send-otp", data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.statusCode == 200) {
            toast.success(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            localStorage.setItem("phone", phone);

            let storeMe = true;
            localStorage.setItem("sendOTP", JSON.stringify(storeMe));
            let timerFlag = true;
            localStorage.setItem("timer", JSON.stringify(timerFlag));
            
            props.history.push("/verify");


          } else {
            toast.error(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setShowLoader(false);
          }
        })
        .catch((error) => {
          toast.error(error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setShowLoader(false);
        });

    }
  }


  return <>

    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />

    <Box className={classes.Login}>
      <Box className={classes.logo}>
        <img src={logo} alt="logo" style={{ height: '65px',width:'65px', padding: '10% 0 5% 0' }} />
      </Box>
      <Box style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '80%' }}>
        <Box style={{ width: '100%', height: '50%', textAlign: 'center' }}>
          <img src={demo} alt="Demo Image" style={{ width: '75%' }} />
        </Box>

        <Box className={classes.loginform}>
          <p style={{ fontSize: 18, color: '#C7222A', textAlign: 'left', fontFamily: 'Poppins', fontWeight: '500' }}>Login/Signup</p>
          <MotionAnimate
            animation='fadeInUp'
            reset={true}
            delay={0.5}
            speed={1}>

            <form onSubmit={onUserSubmit}>
              <TextField className={classes.input} placeholder="Enter Phone Number" value={phone} onChange={(e) => {
                const value = e.target.value

                setPhone(value);

                if (value.length > 10) {
                  setPhoneError(true);
                }
                else{
                  setPhoneError(false);
                }

              }} type="number" />
              {phoneError ? (
                <p
                  style={{
                    color: "#dc3545",
                    fontSize: ".875em",
                    marginTop: "0.25rem",
                    textAlign: 'right'
                  }}
                >
                  Please enter a valid phone number.
                </p>
              ) : (
                <></>
              )}

              

                 <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                 <Button onClick={handleClickOpen} className={classes.Helpbtn}>Need Help?</Button>
                {showLoader ?
                  <>
                    <img src={loader} alt="loader" style={{ width: '50px', height: '50px' }} />
                  </>
                  :
                  <Button size="large" className={classes.loginbtn} type="submit">
                    Send OTP
                  </Button>
                }
              </Box>
              
            </form>
            <Dialog className={classes.Modal}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Box style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'85%',margin:'10px auto 0px'}}>
          <h2 style={{fontFamily:'Poppins',fontSize:20}}>Need Help?</h2>
           <Button onClick={handleClose} color="primary" style={{width:30,height:30,minWidth:30,borderRadius:50,background:'#C7222A',color:'#fff',display:'flex',alignItems:'center',justifyContent:'center',}}>
            X
          </Button></Box>
        <DialogContent>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "88%",
            background:'#fff',
            overflowY:'auto',
          }}
        >
            <Box className={classes.ReportTable}>
            <List component="nav" aria-label="main mailbox folders">
            <ListItem button className={classes.SupportNav}>
            <Mailto email="abw.wellnesssaver@adityabirlacapital.com" subject="Hello" body="Hello world!" style={{width:'100%',display:'flex'}}>
                <Box style={{display:'flex',alignItems:'center',justifyContent:'space-between',background:'rgb(240 227 227)',borderRadius:15,padding:15,}}>
                <Box><p>Email:</p><p style={{ wordBreak: 'break-word', fontSize: '10px'}}>abw.wellnesssaver@adityabirlacapital.com</p></Box>
                <Box><img src={Email} alt="email"/></Box>
                </Box>
                </Mailto>
            </ListItem>
            <ListItem>
            <Callto phone="+918369644246">
            <Box style={{display:'flex',alignItems:'center',justifyContent:'space-between',background:'rgb(245 200 200)',borderRadius:15,padding:15,}}>
                <Box><p>Phone:</p><p>+91-8369644246</p></Box>
                <Box><img src={Call} alt="Call"/></Box>
                </Box>
            </Callto>
            </ListItem>
            </List>
            </Box>
        </Box>
        </DialogContent>
       
      </Dialog>
          </MotionAnimate>
        </Box>

      </Box>
    </Box>
  </>;
}

export default Login;
const useStyles = makeStyles(() => ({
  Login: {
    display: 'flex',
    height: '100vh',
    left: 0,
    right: 0,
    flexDirection: 'column',
    padding: '0 30px 0',
    '& a': {
      textDecoration: 'none'
    }
  },
  Modal:{
    '& .MuiDialog-paper':{
      margin:'0px !important',
    },
    '& .MuiPaper-rounded':{
      width:'90%',
      borderRadius:15
    },
    '& .MuiListItem-gutters':{
      paddingLeft:0,
      paddingRight:0
    }
  },
  ReportTable:{
    width:'100%',
    paddingBottom:20,
    '& p':{
        fontFamily:'Poppins',
        fontSize:16,
        margin:0,
        fontWeight:'500'
    },
    '& a':{
      width:'100%',
    }
},
  Helpbtn:{
    color:'#C7222A',
    textTransform:'capitalize',
    fontFamily:'Poppins',
    fontSize:16
  },
  input: {
    border: 'none',
    borderRadius: '10px',
    height: '50px',
    width: '100%',
    marginBottom: '20px'
  },
  loginform: {
    width: '100%',
    height: '50%',
    paddingTop: '0%',
    paddingBottom: '10%',
    '& .MuiInput-underline:before': {
      display: 'none'
    },
    '& .MuiInput-underline:after': {
      display: 'none'
    },
    '& .MuiInput-formControl': {
      height: '50px',

    },
    '& .MuiInputBase-input': {
      height: '50px',
      borderRadius: '10px',
      background: '#fff',
      padding: '0 15px',
      border: '1px solid #000000'
    },
    '& .MuiInput-input:focus': {
      border: '1px #7087A7 solid',
      boxShadow: '2px 2px 10px 1px rgba(0,0,0,0.3)'
    }
  },
  loginbtn: {
    background: 'linear-gradient(90deg, #C7222A 0%, #C7222A 100%)',
    padding: '0 20px',
    width: '142px',
    height: '50px',
    borderRadius: '50px',
    color: '#fff',
    marginTop: '0',
    textTransform: 'capitalize',
    '&:hover': {
      background: '#333'
    }
  },
  logo: {
    height: '20%',
  }
}));